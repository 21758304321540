/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useEffect, useState, useReducer, useContext, useMemo, useCallback } from 'react'
import { Button, Divider, Form, Icon, Input } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import produce from 'immer'
import { notify } from 'react-notify-toast'
import { UltyModalContext } from '../Shared/UltyModal/UltyModalContext'
import ItemSelector from '../Items/ItemElements/ItemSelector'
import useModified from './useModified'
import { ItemList } from './ItemList/List/ItemList'
import {
    canSaveCategory,
    saveCategory,
    deleteCategory,
    getItemsFromCategory,
} from '../../services/Category/Category.service'

const initialState = {
    category: {
        id: 0,
        name: '',
        description: '',
        position: 0,
        items: [],
    },
}

const useCategoryItemCache = () => {
    const [cache, setMap] = useState(new Map())
    const retrieve = async (categoryId) => {
        if (cache.has(categoryId)) {
            return cache.get(categoryId)
        }
        const items = await getItemsFromCategory(categoryId)
        setMap(new Map(cache.set(categoryId, items)))
        return items
    }
    return {
        retrieve,
        invalidate: () => setMap(new Map()),
    }
}


const categoryEditReducer = (state, action) => {
    switch (action.type) {
        case 'SET_CATEGORY':
            return produce(state, draft => {
                draft.category = {
                    id: action.payload.id,
                    name: action.payload.name || '',
                    description: action.payload.description || '',
                    position: action.payload.position,
                    items: action.payload.items,
                }
            })
        case 'SET_CATEGORY_FIELD':
            return produce(state, draft => {
                draft.category[action.payload.name] = action.payload.value
            })
        case 'SET_ITEMS':
            return produce(state, draft => {
                draft.category.items = action.payload.items
            })
        default:
            throw Error(`Action of categoryEditReducer: '${action.type}' not found`)
    }
}

const CategoryEdit = ({setId, slotId, category, onSaveCategory, onDeleteCategory, isParent}) => {
    const [t] = useTranslation()
    const [state, dispatch] = useReducer(categoryEditReducer, initialState)
    const [isLoading, setIsLoading] = useState(false)
    const {handleUltyModal} = useContext(UltyModalContext)
    const {setModified} = useModified()

    const {retrieve, invalidate} = useCategoryItemCache()

    useEffect(() => {
        dispatch({type: 'SET_CATEGORY', payload: category ? {...category, items: []} : initialState.category})
    }, [category])

    useEffect(() => {
        (async function loadItems() {
            if (category?.id) {
                setIsLoading(true)
                const items = await retrieve(category.id)
                setIsLoading(false)
                dispatch({type: 'SET_ITEMS', payload: {items}})
            }
        })()
    }, [category])

    const handleSaveCategory = async () => {
        setModified(false)

        if (isParent) {
            return
        }

        setIsLoading(true)
        try {
            await saveCategory(setId, slotId, state.category)
            onSaveCategory()
            notify.show(t('global.registerSuccess'), 'success')
        } catch (e) {
            notify.show(`${t(`category_list.${e.message}`)}`, 'error')
        } finally {
            invalidate()
            setIsLoading(false)
        }
    }

    const handleDefineDeleteCategoryModal = async () => {
        const modalSettings = {
            title: t('category_edit.remove', {name: state.category.name}),
            content: <p>{t('category_edit.remove_confirm')}</p>,
            onValidate: async () => {
                await deleteCategory(setId, slotId, state.category.id)
                onDeleteCategory()
                handleUltyModal(false)
                setModified(false)
            },
        }
        handleUltyModal(true, modalSettings)
    }

    const deleteTemporaryCategory = () => {
        setModified(false)
        onDeleteCategory()
    }

    const handleAddItem = useCallback((itemToAdd) => {
        setModified(true)
        dispatch({type: 'SET_ITEMS',
            payload: {
                items: [...state.category.items, {
                    ...itemToAdd,
                    image: itemToAdd.images.find(image => image.isDefault)?.url,
                }],
            },
        })
    }, [setModified, state.category.items])

    const handleDeleteItem = (itemToDelete) => {
        setModified(true)
        dispatch({type: 'SET_ITEMS', payload: {items: state.category.items.filter(i => i.id !== itemToDelete.id)}})
    }

    const handleOnSortItems = (sortedItems) => {
        setModified(true)
        dispatch({type: 'SET_ITEMS', payload: {items: sortedItems}})
    }

    const handleDeleteCategory = async () => {
        if (!state.category.id) {
            deleteTemporaryCategory()
        } else {
            await handleDefineDeleteCategoryModal()
        }
    }

    const handleCategoryFieldChange = (name, value) => {
        setModified(true)
        dispatch({type: 'SET_CATEGORY_FIELD', payload: {name, value}})
    }

    return (<>
        <div css={css`
            display: flex;
            justify-content: flex-end;
        `}>
            {!isParent && (
              <>
                  <Button
                    type="button"
                    color="red"
                    onClick={handleDeleteCategory}
                    loading={isLoading}
                  >
                      <Icon name={'delete'}/>
                      {t('global.delete')}
                  </Button>

                  <Button
                    type="submit"
                    color="teal"
                    onClick={handleSaveCategory}
                    loading={isLoading}
                    disabled={!canSaveCategory(state.category)}
                  >
                      {t('global.save')}
                  </Button>
              </>
            )}
        </div>

        <Form.Field
          control={Input}
          onChange={(e, {name, value}) => handleCategoryFieldChange(name, value)}
          value={state.category.name}
          readOnly={isParent}
          name="name"
          label={t('category_edit.name')}
          placeholder={t('category_edit.name')}
        />

        <Form.Field
          control={Input}
          onChange={(e, {name, value}) => handleCategoryFieldChange(name, value)}
          value={state.category.description}
          name="description"
          readOnly={isParent}
          label={t('category_edit.description')}
          placeholder={t('category_edit.description')}
        />

        <div css={css`
            font-style: italic;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            padding: 10px 0;
        `}>
            {t('product_list.num_items', {n: `${state.category.items.length}`})}
        </div>

        <Divider/>

        {useMemo(() => {
            if (isParent) {
                return null
            }

            return (
              <ItemSelector
                filters={{
                    types: ['MENU', 'PRODUCT'],
                }}
                excludedItemIds={state.category.items.map(i => i.id)}
                onItemAdd={handleAddItem}
              />
            )
        }, [handleAddItem, state.category.items, isParent])}

        <ItemList
          items={state.category.items}
          onDeleteItem={handleDeleteItem}
          onSortItems={handleOnSortItems}
          isParent={isParent}
        />
    </>)
}

CategoryEdit.propTypes = {
    digitalMerchantId: PropTypes.string,
    platformId: PropTypes.string,
    category: PropTypes.object,
    onSaveCategory: PropTypes.func.isRequired,
    onDeleteCategory: PropTypes.func.isRequired,
}

export default CategoryEdit
