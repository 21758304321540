/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Dropdown, Button, Icon, Modal } from 'semantic-ui-react';
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery';
import { MOBILE_THRESHOLD } from '../../../constants';

const UltyPopup = ({ label, icon, isLoading, items }) => {
    const isMobile = useMediaQuery(`(max-width: ${MOBILE_THRESHOLD}px)`);

    const [showing, setShowing] = useState(false);

    return isMobile ? (
        <>
            <Modal
                basic
                onClose={() => setShowing(false)}
                onOpen={() => setShowing(true)}
                open={showing}
                closeIcon
                size='small'
                floating
                css={css`
                  height: 100%;
                `}
                trigger={
                    <Button
                        icon={icon || "bars"}
                        onClick={() => setShowing(true)}
                        {...(isMobile ? {} : { labelPosition: "left" })}
                        content={label}
                    />}
            >
                <Modal.Content css={css`
                  display: flex !important;
                  height: 100%;
                  align-items: center;
                  justify-content: center;
                  padding-left: 10%;
                  padding-right: 10%;
                `}>
                    <div css={css`
                      flex: 1;
                      margin-left: 2rem;
                      margin-right: 2rem;
                      display: flex;
                      flex-direction: column;

                      button {
                        padding: 1.5em !important;

                        &:not(:last-child) {
                          margin-bottom: 1rem;
                        }
                      }
                    `}>
                        {items.filter(i => i.active).map(i => (
                            <Button
                                key={i.label}
                                size="large"
                                fluid
                                color={i.color}
                                onClick={() => {
                                    setShowing(false);
                                    i.onClick();
                                }}>
                                {i.icon && (
                                    <Icon name={i.icon} />
                                )}
                                {i.label}
                            </Button>
                        ))}
                    </div>
                </Modal.Content>
            </Modal>
        </>
    ) : (
        <Dropdown
            button
            loading={isLoading}
            labeled={!!label}
            text={label}
            className={label && 'icon'}
            icon={icon || "bars"}
            floating css={css`
          padding: 0.78em !important;

          &>i {
            margin: 0 !important;
          }
        `}>
            <Dropdown.Menu className="left">
                {items.filter(i => i.active).map(i => (
                    <Dropdown.Item
                        key={i.label}
                        onClick={i.onClick}>
                        {i.icon && (
                            <Icon name={i.icon} color={i.color} />
                        )}
                        {i.label}
                    </Dropdown.Item>
                ))}

            </Dropdown.Menu>
        </Dropdown>
    )
};

UltyPopup.propTypes = {
    label: PropTypes.string,
    icon: PropTypes.string,
    isLoading: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.shape({
        onClick: PropTypes.func.isRequired,
        icon: PropTypes.string,
        color: PropTypes.string,
        label: PropTypes.string.isRequired,
        active: PropTypes.bool.isRequired
    }))
};

export default UltyPopup;
