/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import React, {useState, useEffect, useCallback, useContext, useRef, useMemo} from 'react';
import { updateOrderStatus } from '../../../helpers/api'

import {
    Segment,
    Grid,
    Image,
    Icon,
    Placeholder,
    Step,
    Message,
    Button, Loader, Ref, List, Label
} from 'semantic-ui-react';
import { notify } from 'react-notify-toast'
import formatDate from '../../../helpers/formatDate'

import { UltyModalWrapperContext } from '../../Shared/UltyModalWrapper/UltyModalWrapperContext';

import {acceptOrder, denyOrder, getOrder} from '../../../services/Order/Order.service';
import {useTranslation} from 'react-i18next';
import OrderDenyReasonMessage from '../OrderDenyReason/OrderDenyReasonMessage';
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery';
import {OrderDetailContext} from './OrderDetailContext';
import {useParams} from 'react-router-dom';
import OrderEvents from '../OrderEvents/OrderEvents';
import OrderStore from '../OrderStore';
import RequestCourier from './RequestCouriers';
import UltyPopup from '../../Shared/UltyPopup/UltyPopup';
import CancelOrder from './CancelOrder';
import useBreadcrumb from '../../NavBar/useBreadcrumb';
import useUser from '../../Shared/UserProvider/useUser';
import { MOBILE_THRESHOLD } from '../../../constants';
import {OrderItemsList, orderItemsListDisplayMode} from './OrderItemList/OrderItemsList';
import produce from 'immer';
import OrderFlowActions from './OrderFlowActions/OrderFlowActions';
import OrderDelivery from './OrderDelivery';
import OrderPOSDelivery from './OrderPOSDelivery';
import { isProviderAPos } from '../../../services/Provider/Provider.service';
import PlatformModule from '../../../services/Platform/PlatformModule';
import { PrintOrderButton } from '../../../printing/components/PrintButton/PrintOrderButton';
import { getClientName } from '../../../helpers/order';
import { addMinutes } from 'date-fns';
import { canBeModified } from '../../../services/Order/order.helper';
import { ScanningInformationLabel, useScanning } from '../../../scanning/scanning.service';
import { usePrevious } from '../../../helpers/usePrevious';

const PHONES = {
    'ubereats': '0805 084 286',
    'deliveroo': '09 77 55 03 31',
    'justeat': '01 86 65 46 15',
};

const PHONES_COUNTRIES = {
    'FR': PHONES,
    'BE': {
        'ubereats': '+32 2 808 66 28',
        'deliveroo': '+32 2 895 40 06 (FR) / +32 2 895 40 07 (NL)',
        'justeat': '',        
    }
};

const getPhones = (countryCode) => {
    return PHONES_COUNTRIES[countryCode] ?? PHONES;
}

const OrderDetail = () => {
    const [t] = useTranslation();
    const {id} = useParams();
    const {setPaths} = useBreadcrumb();
    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState(null);
    const [isDenying, setIsDenying] = useState(false);
    const [isDenyAcceptLoading, setIsDenyAcceptLoading] = useState(false);
    const store = OrderStore.useContainer();
    const {user} = useUser();

    const productsRef = useRef();
    const isMobile = useMediaQuery(`(max-width: ${MOBILE_THRESHOLD}px)`);
    const [displayMode, setDisplayMode] = useState(isMobile ? orderItemsListDisplayMode.PRODUCTS : orderItemsListDisplayMode.LINES);

    const {isModifying, setIsModifying} = useContext(OrderDetailContext);
    const wasModifying = usePrevious(isModifying);


    useEffect(() => {
        if (window.Android && window.Android.stopNotifications) {
            window.Android.stopNotifications();
        }
    }, []);

    useEffect(() => {
        if(!wasModifying && isModifying)
            productsRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    },[isModifying])

    const canOrderBeModified = useMemo(() => canBeModified(order), [order]);
    const onScanDetected = useCallback(() => {
        if(canOrderBeModified) {
            setDisplayMode(orderItemsListDisplayMode.PRODUCTS);
            setIsModifying(true);
        }
    },[canOrderBeModified]);
    useScanning(onScanDetected);


    useEffect(() => {
        setPaths([{
            text: t('breadcrumb.home'),
            link: true,
            path: '/'
        }, {
            text: t('breadcrumb.orders'),
            link: true,
            path: `/orders/${isProviderAPos(user.provider) ? 'current' : 'finished'}`,
        }, {
            text: order?.brand?.name||t('global.loading'),
            link: false
        }, {
            text: order ? `${order.platform.name} - ${order.displayId}` : t('global.loading'),
            link: false
        }]);
    }, [order]);

    const shouldDisplayTimer = useMemo(() => {
        return !!order
            && (
                order.status === 'RECEIVED'
                || (
                    (order.status === 'ACCEPTED' || order.status === 'PREPARING')
                    && order.platform.displayPreparationTimer)
                )
    },[order])

    const loadOrder = useCallback(async () => {
        setLoading(true);
        try {
            const order = await getOrder(id);

            order.orderItems.sort((i1, i2) => {
                if (!i1.categoryName && !i2.categoryName) {
                    return i1.id - i2.id;
                } else if (!i1.categoryName) {
                    return -1;
                } else if (!i2.categoryName) {
                    return +1;
                } else {
                    return i1.categoryName.localeCompare(i2.categoryName);
                }
            });

            setOrder(order);
            setIsModifying(false);

            if (window.Android && window.Android.acknowledgeOrder) {
                window.Android.acknowledgeOrder(order.id);
            }

            window.scrollTo({ top: 0, behavior: 'smooth' });
        } catch (e) {
            console.log(e);
            notify.show(`${t('global.anErrorOccurred')}`, 'error');
        } finally {
            setLoading(false);
        }
    }, [id, setIsModifying, t]);

    useEffect(() => {
        loadOrder();
    }, [loadOrder, t]);

    useEffect(() => {
        const currentOrder = (store.orders||[]).find(o => o.id === parseInt(id, 10));
        if (currentOrder) {
            if (currentOrder.status !== order?.status && order?.status === 'RECEIVED') {
                setIsModifying(false);
            }

            if (['DENIED', 'CANCELLED', 'COMPLETED'].includes(currentOrder.status)) {
                setOrder(produce(draft => {
                    if (draft) {
                        draft.status = currentOrder.status;
                    }
                }));
            }

            setOrder(produce(draft => {
                if (draft) {
                    draft.customer = currentOrder.customer;
                }
            }));
        }
    }, [store.orders, id, order?.status, setIsModifying]);

    const changeOrderStatus = async (status) => {
        setLoading(true);

        let res = await updateOrderStatus(id, status)
        if (res.status === 'SUCCESS') {
            await loadOrder();
        } else {
            if (res.error && res.error === 'CANCELLED') {
                notify.show(t('order.error_cancelled'), 'error');
                await loadOrder();
            } else {
                notify.show(t('global.anErrorOccurred'), 'error');
            }
        }
    }

    // Preparing
    const getPreparingProps = () => {
        let preparingProps = {
            link: order && ['ACCEPTED'].includes(order.status),
            active: order.status === 'PREPARING',
            completed: order && ['READY', 'COMPLETED'].includes(order.status)
        }

        if (preparingProps.link) {
            preparingProps.onClick = () => changeOrderStatus('PREPARING')
        }

        return preparingProps
    }

    // Ready
    const getReadyProps = () => {
        let readyProps = {
            link: order && ['ACCEPTED', 'RECEIVED', 'PREPARING'].includes(order.status),
            active: order.status === 'READY',
            completed: order && ['COMPLETED'].includes(order.status)
        }

        if (readyProps.link) {
            readyProps.onClick = () => changeOrderStatus('READY')
        }

        return readyProps
    }

    // Completed
    const getCompletedProps = () => {
        let completedProps = {
            link: order && ['RECEIVED', 'ACCEPTED', 'PREPARING', 'READY'].includes(order.status),
            active: order.status === 'COMPLETED',
        }

        if (completedProps.link) {
            completedProps.onClick = () => changeOrderStatus('COMPLETED')
        }

        return completedProps
    }

    const handleDenyOrder = () => {
        setIsModifying(false);
        setIsDenying(!isDenying);
    }

    const handleFulfillmentIssues = () => {
        setIsDenying(false);
        setIsModifying(true);
    }

    const handleNextOrderStatus = async (orderStatus) => {
        if (orderStatus === 'RECEIVED'){
            await handleAcceptOrder();
        }

        setIsDenyAcceptLoading(false);
        setIsModifying(false);

        await loadOrder();
    }

    const handleMarkAsReadyOrder = async () => {
        try {
            setIsDenyAcceptLoading(true);
            await changeOrderStatus('READY');
        } catch (e) {
            notify.show(`${t('global.anErrorOccurred')}`, 'error');
        } finally {
            setIsDenyAcceptLoading(false);
            setIsModifying(false);
        }
    }

    const handleAcceptOrder = async (delay) => {
        try {
            setIsDenyAcceptLoading(true);
            const acceptedOrder = await acceptOrder(order, delay);
            setOrder(acceptedOrder);
            setIsDenyAcceptLoading(false);
        } catch (e) {
            console.log(e);
            notify.show(`${t('global.anErrorOccurred')}`, 'error');
        } finally {
            setIsDenyAcceptLoading(false);
            setIsModifying(false);
        }
    }

    const handleDenyReasonSubmit = async (denyReason) => {
        setIsDenyAcceptLoading(true);
        const deniedOrder = await denyOrder(order, {type: denyReason.type.name, details: denyReason.details});
        setOrder(deniedOrder);
        setIsDenying(false);
        setIsDenyAcceptLoading(false);
    }

    const { handleUltyModalWrapper } = React.useContext(UltyModalWrapperContext);
    const handleUpdateCourier = async (order) => {
        const modalSettings = {
            title: `${t('order.couriers.title')}`,
            component: <RequestCourier order={order} afterSubmit={async () => {
                await loadOrder();
                handleUltyModalWrapper(false);
            }} />,
        };
        handleUltyModalWrapper(true, modalSettings);
    }

    const handleCancelOrder = async (order) => {
        const modalSettings = {
            title: `${t('order.cancel.header')}`,
            component: <CancelOrder order={order} afterSubmit={async () => {
                await loadOrder();
                handleUltyModalWrapper(false);
            }} />,
        };
        handleUltyModalWrapper(true, modalSettings);
    }

    const orderLifetime = order?.receivedOrderLifetime || order?.platform.receivedOrderLifetime || (order?.platform.module === 'ubereats' && 60*6)
    const handleShowHistory = async () => {
        const modalSettings = {
            component: <OrderEvents orderId={order.id} afterSubmit={async () => {
                handleUltyModalWrapper(false);
            }} />,
        };
        handleUltyModalWrapper(true, modalSettings);
    }


    const getClientPhone = (client) => {
        try {
            const m = (client||'').match(/^([^()]*) \((.*)\)$/);
            if (m) {
                return m[2];
            }
        } catch {}

        return client||'N/C';
    }

    return (
        <>
            <Loader active={loading} />
            {order &&
                <>
                    {order.status === 'CANCELLED' || order.status === 'EXPIRED' ? (
                        <Message
                            icon='times'
                            header={t(`order.status.${order.status}`)}
                            content={order.status === 'CANCELLED' ? t('order.cancelled') : t('order.expired')}
                            error
                        />
                    ) : (
                        <Segment loading={loading} basic css={css`
                          ${isMobile && 'padding: 0 !important;'}
                        `}>
                            {shouldDisplayTimer &&
                                <OrderFlowActions
                                    order={order}
                                    orderLifetime={orderLifetime}
                                    isDenying={isDenying}
                                    isDenyAcceptLoading={isDenyAcceptLoading}
                                    onDenyOrder={handleDenyOrder}
                                    onMarkAsReady={handleMarkAsReadyOrder}
                                    onAcceptOrder={handleAcceptOrder}
                                    onFulfillmentIssues={handleFulfillmentIssues}
                                    onDenyReasonSubmit={handleDenyReasonSubmit}
                                />
                            }

                            {order.status === 'DENIED' &&
                                <OrderDenyReasonMessage order={order}/>
                            }


                            {!['RECEIVED', 'DENIED', 'CANCELLED'].includes(order.status) &&
                                <Step.Group unstackable ordered widths={4} css={isMobile ? css`
                                z-index:1;

                                  .step {
                                    display: flex !important;
                                    flex-direction: column !important;

                                    &::before {
                                      margin-right: 0 !important;
                                      margin-bottom: 1rem !important;
                                    }
                                    .content {
                                      text-align: center;
                                      height: 100%;
                                      display: flex !important;
                                      flex-direction: row;
                                      align-items: center;

                                      .title {
                                        font-size: 0.9em !important;
                                      }
                                    }
                                  }
                                ` : css``}>
                                    <Step active={order.status === 'ACCEPTED'}
                                          completed={order && ['COMPLETED', 'PREPARING', 'READY'].includes(order.status)}>
                                        <Step.Content>
                                            <Step.Title>{t('order.status.ACCEPTED')}</Step.Title>
                                        </Step.Content>
                                    </Step>

                                    <Step {...getPreparingProps()}>
                                        <Step.Content>
                                            <Step.Title>{t('order.status.PREPARING')}</Step.Title>
                                        </Step.Content>
                                    </Step>

                                    <Step {...getReadyProps()}>
                                        <Step.Content>
                                            <Step.Title>{t('order.status.READY')}</Step.Title>
                                        </Step.Content>
                                    </Step>

                                    <Step {...getCompletedProps()}>
                                        <Step.Content>
                                            <Step.Title>{t('order.status.COMPLETED')}</Step.Title>
                                        </Step.Content>
                                    </Step>
                                </Step.Group>}
                        </Segment>
                    )}

                    <Segment loading={loading}>
                    {canOrderBeModified && <ScanningInformationLabel/>}
                        <Ref innerRef={productsRef}>

                            <>
                                <Grid>
                                    <Grid.Column width={10}>
                                        <Button.Group>
                                            <Button icon disabled={isModifying} active={displayMode === orderItemsListDisplayMode.LINES} onClick={() => setDisplayMode(orderItemsListDisplayMode.LINES)}>
                                                <Icon name='th list' />
                                            </Button>
                                            <Button icon disabled={isModifying} active={displayMode === orderItemsListDisplayMode.PRODUCTS} onClick={() => setDisplayMode(orderItemsListDisplayMode.PRODUCTS)}>
                                                <Icon name='th' />
                                            </Button>
                                        </Button.Group>
                                    </Grid.Column>
                                    <Grid.Column width={6} textAlign="right">
                                        <PrintOrderButton order={order}/>
                                        {order && order.status && (
                                            <UltyPopup items={[
                                                ...(!['CANCELLED', 'DENIED', 'RECEIVED', 'EXPIRED'].includes(order.status) ? [{
                                                    icon: 'times',
                                                    color: 'red',
                                                    label: t('order.cancel.button'),
                                                    onClick: () => handleCancelOrder(order),
                                                    active: true,
                                                }] : []),
                                                ...(!['CANCELLED', 'DENIED', 'RECEIVED', 'COMPLETED', 'EXPIRED'].includes(order.status) ? [{
                                                    icon: 'shipping fast',
                                                    color: 'teal',
                                                    label: t('order.couriers.button'),
                                                    onClick: () => handleUpdateCourier(order),
                                                    active: order.platform.module === 'ubereats'
                                                }, {
                                                    icon: 'pencil',
                                                    color: 'yellow',
                                                    label: t('order.modify'),
                                                    onClick: () => {
                                                        setIsModifying(true);
                                                    },
                                                    active: order.platform.canEditOrder && order.platform.module !== 'deliveroo'
                                                }] : []),
                                                {
                                                    icon: 'history',
                                                    color: 'teal',
                                                    label: t('order.history.header'),
                                                    onClick: handleShowHistory,
                                                    active: true
                                                }
                                            ]} />
                                        )}
                                    </Grid.Column>
                                </Grid>

                                <Grid>
                                    {!isMobile && (
                                        <Grid.Column tablet={4}>
                                            {loading ? (
                                                <Placeholder>
                                                    <Placeholder.Image square />
                                                </Placeholder>
                                            ) : (
                                                <Image src={order.platform.logo} />
                                            )}
                                        </Grid.Column>
                                    )}
                                    <Grid.Column tablet={12} mobile={16} computer={12} css={css`
                                      display: flex !important;
                                      flex-direction: column;

                                      label {
                                        font-weight: bold;
                                      }
                                    `}>
                                        <>
                                            <div css={css`
                                              display: flex;
                                            `}>
                                                { isMobile && (
                                                    <div css={css`
                                                      display: flex;
                                                      flex-direction: column;
                                                    `}>
                                                        {loading ? (
                                                            <Placeholder>
                                                                <Placeholder.Image square />
                                                            </Placeholder>
                                                        ) : (
                                                            <Image size="tiny" src={order.platform.logo} />
                                                        )}
                                                        <div css={css`
                                                          font-weight: bold;
                                                          text-align: center;
                                                          font-size: 1.2em;
                                                          padding-top: 5px;
                                                        `}>{order.displayId}</div>
                                                    </div>
                                                )}
                                                <List relaxed css={css`
                                                  flex: 1;
                                                  ${isMobile ? 'padding-left: 1em !important;' : ''}
                                                  margin-top: 0 !important;

                                                  div.header {
                                                    color: #4183c4 !important;
                                                `}>
                                                    <List.Item>
                                                        <List.Icon name='dolly' size='large' verticalAlign='middle' />
                                                        <List.Content>
                                                            <List.Header>{t('order.type')}: {order.orderType ? t(`order.type_${order.orderType}`) : 'N/C'}</List.Header>
                                                        </List.Content>
                                                    </List.Item>
                                                    <List.Item>
                                                        <List.Icon name='hourglass half' size='large' verticalAlign='middle' />
                                                        <List.Content>
                                                            <List.Header>{t('order.pickup_at')}: {order.pickupAt ? formatDate(order.pickupAt) : 'N/C'}</List.Header>
                                                        </List.Content>
                                                    </List.Item>
                                                    <List.Item>
                                                        <List.Icon name='user' size='large' verticalAlign='middle' />
                                                        <List.Content>
                                                            <List.Header>
                                                                {getClientName(order.customer)}
                                                                <span css={css`margin-left: 1rem`}>
                                                                    {order.customerOrderCount === 1 && (
                                                                        <Label color='yellow' size='mini'>
                                                                            <Icon name='thumbs up' />
                                                                            {t(isMobile ? 'order.detail.first_order_mobile' : 'order.detail.first_order')}
                                                                        </Label>
                                                                    )}

                                                                    {[PlatformModule.DELIVEROO].includes(order.platform.module) && order.customerOrderCount > 1 && (
                                                                        <Label color='green' size='mini'>
                                                                            <Icon name='rocket' />
                                                                            {t(isMobile ? 'order.detail.nth_order_mobile' : 'order.detail.nth_order', { count: order.customerOrderCount })}
                                                                        </Label>
                                                                    )}
                                                                </span>
                                                            </List.Header>
                                                        </List.Content>
                                                    </List.Item>
                                                    <List.Item>
                                                        <List.Icon name='phone' size='large' verticalAlign='middle' />
                                                        <List.Content>
                                                            <List.Header>{getClientPhone(order.customer)}</List.Header>
                                                        </List.Content>
                                                    </List.Item>
                                                </List>
                                            </div>
                                        </>

                                        { ['ubereats', 'deliveroo', 'justeat'].includes(order.platform.module) && (
                                            <Message icon compact>
                                                <Icon name='phone volume' />
                                                <Message.Content>
                                                    <Message.Header css={css`
                                                    text-align: center;
                                                `}>{`${t('order.hotline')} - ${order.platform.name}`}</Message.Header>
                                                    <div css={css`
                                                    font-weight: bold;
                                                    text-align: center;
                                                    font-size: 1.14285714em;
                                                `}>{getPhones(user.getCompanyCountry())[order.platform.module]}{order.platform.module === 'deliveroo' && order.platform.storeId ? ` - Restaurant ID : ${order.platform.storeId}` : ''}</div>
                                                </Message.Content>
                                            </Message>
                                        )}
                                        { isModifying && ['justeat'].includes(order.platform.module) && (
                                            <Message icon compact warning>
                                                <Icon name='warning volume' />
                                                <Message.Content>
                                                    <Message.Header css={css`
                                                    text-align: center;
                                                    `}>
                                                        {`${t('order.modify_limited_support.title',{ platformName:order.platform.name })}`}
                                                    </Message.Header>
                                                    <div css={css`
                                                    text-align: center;
                                                `}>{t('order.modify_limited_support.message',{ platformName:order.platform.name })}</div>
                                                </Message.Content>
                                            </Message>
                                        )}

                                        { order.delivery?.status && (
                                            <OrderDelivery delivery={order.delivery} />
                                        )}

                                        { order.orderType === "POS_DELIVERY" && (
                                            <OrderPOSDelivery orderId={order.id} />
                                        )}

                                        <OrderItemsList
                                            displayMode={displayMode}
                                            order={order}
                                            isModifying={isModifying}
                                            onSave={handleNextOrderStatus}
                                            onCancel={() => setIsModifying(false)}
                                        />

                                        <div css={css`
                                          display: flex;
                                          flex-direction: ${isMobile ? 'column' : 'row'};
                                        `}>
                                            <div css={css` flex: 1;
                                              white-space: pre-wrap;
                                              margin-top: ${isMobile ? '10px' : 0}`}>
                                                <Icon name="sticky note" />{t('order.notes')}:
                                                <div css={css` padding: 4px; `}>
                                                    {(order.notes || '') || 'N/C'}
                                                </div>
                                            </div>
                                        </div>
                                    </Grid.Column>
                                </Grid>
                            </>
                        </Ref>
                    </Segment>
                </>
            }
        </>
    )
}

export default OrderDetail
