/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import {useState, useCallback, useContext} from 'react'
import PropTypes from 'prop-types';
import { Item, Label, Form, Icon, Dropdown, Button } from 'semantic-ui-react';
import { updateCategoryLinksSet, updateCategoryLinksMerchants, deleteCategoryLink } from '../../services/Category/Category.service';
import useUser from '../Shared/UserProvider/useUser';
import { useTranslation } from 'react-i18next';
import MarkupSetupEdit from './MarkupSetupEdit/MarkupSetupEdit'
import {UltyModalWrapperContext} from '../Shared/UltyModalWrapper/UltyModalWrapperContext'
import {isProviderAPos} from '../../services/Provider/Provider.service'

import { UltyDropdown } from '@ulty/ulty-ui'
import { ComingSoon } from '../Shared/ComingSoon/ComingSoon';
const CategorySetEditable = ({ link, sets, canCreateLinks, reload }) => {
    const [t] = useTranslation();

    const [isEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [setId, setSetId] = useState(link.categorySet.id);

    const handleSave = async () => {
        setLoading(true);
        await updateCategoryLinksSet(link.id, setId);
        setIsEdit(false);
        await reload();
        setLoading(false);
    }

    const getSetName = useCallback(() => {
        const [set] = sets.filter(s => s.id === setId);
        return set?.name||'';
    }, [sets, setId]);

    if (!isEdit || !canCreateLinks) {
        return (
            <Form.Field css={css` flex: 1; `}>
                <label>{t('categories.set')}: </label><span>{getSetName()}</span> {canCreateLinks && (<Icon name="pencil" onClick={() => setIsEdit(true)} css={css` cursor: pointer; `} />)}
            </Form.Field>
        );
    }

    return (
        <Form.Field>
            <label>{t('categories.set')}: </label>
            <div css={css`
              display: flex;
            `}>
                <Dropdown
                    fluid
                    selection
                    loading={loading}
                    name="sets"
                    onChange={(evt, { value }) => setSetId(value)}
                    value={setId}
                    options={sets.map(dm => ({
                        text: dm.name,
                        value: dm.id,
                        key: dm.id
                    }))}
                />
                <div css={css`
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-left: 10px;
                `}>
                    <Button icon="check" circular size="mini" onClick={handleSave} loading={loading} disabled={loading} />
                </div>
                <div css={css`
                  display: flex;
                  justify-content: center;
                  align-items: center;
                `}>
                    <Button icon="times" circular size="mini" onClick={() => setIsEdit(false)} loading={loading} disabled={loading} />
                </div>
            </div>
        </Form.Field>
    );
}

const CategoryMerchantsEditable = ({ link, merchants, reload }) => {
  const [t] = useTranslation();

    const [isEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedMerchants, setSelectedMerchants] = useState(link.merchants||[]);

  const handleSave = async () => {
    setLoading(true);
    await updateCategoryLinksMerchants(link.id, selectedMerchants);
    setIsEdit(false);
    setLoading(false);
    reload();
  }

    if (!isEdit) {
        return (
            <Form.Field inline css={css` flex: 1; `}>
                <label>{t('global.merchant')}: </label>
                {selectedMerchants.slice(0, 5).map(m => {
                  const found = merchants.find(mm => mm.id === m);
                    return found && (
                        <Label key={m}>{found?.name}</Label>
                    )
                })}
                {selectedMerchants.length > 5 && (
                    <Label>+{selectedMerchants.length - 5}</Label>
                )}
                <Icon name="pencil" onClick={() => setIsEdit(true)} css={css` cursor: pointer; `} />
            </Form.Field>
        )
    }

  return (
    <Form.Field>
      <label>{t('global.merchant')}: </label>
      <div css={css`
        display: flex;
      `}>
        <Dropdown
          fluid
          selection
          multiple
          search
          loading={loading}
          name="sets"
          onChange={(evt, { value }) => value.length > 0 && setSelectedMerchants(value)}
          value={selectedMerchants}
          options={merchants.map(dm => ({
            text: dm.name,
            value: dm.id,
            key: dm.id
          }))}
        />
        <div css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 10px;
        `}>
          <Button icon="check" circular size="tiny" onClick={handleSave} loading={loading} disabled={loading} />
        </div>
        <div css={css`
          display: flex;
          justify-content: center;
          align-items: center;
        `}>
          <Button icon="times" circular size="tiny" onClick={() => {
            setIsEdit(false);
            setSelectedMerchants(link.merchants)
          }} loading={loading} disabled={loading} />
        </div>
      </div>
    </Form.Field>
  );
}

const CategoryLinkItem = ({ canCreateLinks, logo, link,categorySet, merchants, sets, reload }) => {
    const [t] = useTranslation();
    const {user} = useUser();
    const { handleUltyModalWrapper } = useContext(UltyModalWrapperContext)
    const [loading, setLoading] = useState(false);

    const handleDelete = async () => {
        setLoading(true);

    await deleteCategoryLink(link.id);
    await reload();
    setLoading(false);
  }

    const defineManageMarkupModal = () => {
        const modalSettings = {
            component: <MarkupSetupEdit
                categorySetLink={link}
                categorySet={categorySet}
            />
        }

        handleUltyModalWrapper(true, modalSettings)
    }

    return (
        <Item>
            <Item.Image size='tiny' src={logo} />

            <Item.Content>
                <Item.Description css={css`
                  position: relative; 
                `}>
                    <div>
                        <Form.Group widths="equal">
                            <Form.Field inline>
                                <label>{t('global.brand')}: </label><span>{link.brand.name}</span>
                            </Form.Field>
                            <Form.Field inline>
                                <label>{t('global.platform')}: </label><span>{link.platform.name}</span>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <CategorySetEditable sets={sets} link={link} canCreateLinks={canCreateLinks} reload={reload} />
                            { user.provider.type === 'COMPANY' ?
                              ((link.merchants && link.merchants?.length > 0) ?
                                                (<CategoryMerchantsEditable link={link} merchants={merchants} />)
                              : <Item.Extra>
                                  <Label  color="blue" content={t('category_link.default_link')} />
                                </Item.Extra>
                            ) : (
                                <div/>
                            )}
                        </Form.Group>
                    </div>

                    <div css={css`
                      position: absolute;
                      top: 0;
                      right: 0;
                    `}>

                        <div css={css`
                        float:right
                    `}>
                          {(link.provider.type === 'COMPANY' && user.provider.type !== 'COMPANY') && (
                              <Label color="blue">{t('global.company')}</Label>
                          )}

                          {(link.provider.type !== 'COMPANY' || user.provider.type === 'COMPANY') && (
                            <Button color="red" icon size="tiny" loading={loading} onClick={() => handleDelete()} disabled={loading}>
                                <Icon name="times" />
                                {t('global.delete')}
                            </Button>
                          )}
                        </div>
                        <div css={css`
                          margin-top: 3em;
                        `}>
                          <ComingSoon featureGate="markup_on_category">
                            <Button 
                              size='tiny'
                                basic color="teal"
                                onClick={defineManageMarkupModal}
                            >
                                {t('markup.manage_markup')}
                            </Button>
                          </ComingSoon>
                        </div>
                       
                       
                    </div>
                </Item.Description>
            </Item.Content>
        </Item>
    );
}

CategoryLinkItem.propTypes = {
    link: PropTypes.object.isRequired,
    sets: PropTypes.arrayOf(PropTypes.object).isRequired,
    canCreateLinks: PropTypes.bool.isRequired,
    reload: PropTypes.func.isRequired,
}

export default CategoryLinkItem;
