import moment from 'moment'

export const isDayNumberToday = (dayNumber) => {
    return moment().day() === dayNumber
}

export const isInLessThanXMinutes = (dateOrNumber,minutes) => 
    (new Date(dateOrNumber) - new Date()) < (60 *  minutes * 1000);


export const isInMoreThanXMinutes = (dateOrNumber,minutes) => 
    (new Date(dateOrNumber) - new Date()) > (60 *  minutes * 1000);
