
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import React from 'react';

const MarkupList = ({ markupItems }) => {
  const days = [
   'monday',
   'tuesday',
   'wednesday',
   'thursday',
   'friday',
   'saturday',
   'sunday'];

  const getTimePeriodStyle = (startTime, endTime) => {
    const startHour = parseInt(startTime.split(':')[0]);
    const endHour = parseInt(endTime.split(':')[0]);
    const startMinute = parseInt(startTime.split(':')[1]);
    const endMinute = parseInt(endTime.split(':')[1]);
    const totalMinutes = (endHour - startHour) * 60 + (endMinute - startMinute);
    const totalPeriodMax = (1+ endHour - startHour) * 60
    return {
      gridColumn: `${startHour + 1} / ${(endHour + 2)}`,
      width: `${(totalMinutes / totalPeriodMax) * 100}%`,
    };
  };

  return (
    <div css={css`
    .markup-list {
        display: grid; 
        
        gap: 11px 13px; 
        grid: 
          "header header" 1fr
          "monday ." 1fr
          "tuesday ." 1fr
          "wednesday ." 1fr
          "thursday ." 1fr
          "friday ." 1fr
          "satursday ." 1fr
          "sunday ." 1fr
          / auto 1fr; 
      }

      .monday { grid-area: monday; }
      .tuesday { grid-area: tuesday; }
      .wednesday { grid-area: wednesday; }
      .thursday { grid-area: thursday; }
      .satursday { grid-area: satursday; }
      .sunday { grid-area: sunday; }
      
      .header-cell {
        font-weight: bold;
      }
      
      .row {
       
      }
      
      .day-cell {
        font-weight: bold;
      }
      
      .time-range {
        display: flex;
        position: relative;
        height: 20px;
      }
      
      .time-cell {
        flex-grow: 1;
        border-bottom: 1px solid #ccc;
        position: relative;
        overflow: hidden;
      }

      .time-cell-row {
        flex-grow: 1;
        border: 1px solid #ccc;
        border-radius: 20px
        position: relative;
      }
      .left { text-align: left;}
      .middle { text-align: center;}
      .right { text-align: right;}
      
      .positive-markup,
      .negative-markup {
        font-size: 12px;
        font-weight: bold;
      }

      
      .time-range-row {
        display: grid;
        position: relative;
        height: 20px;
      }
      
      .positive-markup {
        color: green;
      }
      
      .negative-markup {
        color: red;
      }
    `}>
<div className="markup-list">
      <div className="row header">
        <div className="header-cell"></div>
      </div>
      <div className="time-range">
          <div className="time-cell left">00:00</div>
          <div className="time-cell middle">12:00</div>
          <div className="time-cell right">23:59</div>
    </div>
      {days.map((day) => {
        const item = markupItems.find((i) => i.daysOfWeek.includes(day));

        return (
            <>
          <div key={day} className={ `row ${day.toLocaleLowerCase()}`}>
            <div className="day-cell">{day}</div>
          </div>

            <div className="time-range-row middle">
              {item ? (
                <>
                  {(item.hours ||[]).map((time) => (
                    <div
                      key={`${day}-${time.from}-${time.to}`}
                      className="time-cell-row"
                      style={getTimePeriodStyle(time.from, time.to)}
                    >
                    {item.markup > 0 && (
                    <div className="positive-markup" title={`${time.from}-${time.to} : ${item.markup}%`}>{item.markup}%</div>
                    )}
                    {item.markup < 0 && (
                        <div className="negative-markup" title={`${time.from}-${time.to} : ${item.markup}%`}>{item.markup}%</div>
                    )}
                    </div>
                  ))}
                
                </>
              ) : (
                <div className="empty-cell"></div>
              )}
            </div>
            </>
        );
      })}
    </div>
    </div>

  );
};

export { MarkupList }