import { isInMoreThanXMinutes } from "../date.service";
import PlatformModule from "../Platform/PlatformModule";

const ORDER_FINAL_STATUS = ['COMPLETED','DENIED','EXPIRED','CANCELLED'];
const ORDER_MODIFIABLE_STATUS = ['RECEIVED', 'ACCEPTED', 'PREPARING'];


//TODO Might be a hook
export const canBeModified = (order) => {
    const {status,platform} = order ?? {};
    if(!platform?.canEditOrder) return false;
    const isDeliverooLegacyFlow = platform?.module === PlatformModule.DELIVEROO && platform?.displayPreparationTimer !== 'reverse';
    if(isDeliverooLegacyFlow) return status === 'RECEIVED';
    
    return ORDER_MODIFIABLE_STATUS.includes(status);
}

export const isOrderScheduledInMoreThanXMinutes = ( order ) => {
    const {pickupAt} = order ?? {}

    return isInMoreThanXMinutes(pickupAt || new Date(),60)

}