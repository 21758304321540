/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useMemo, useState } from 'react';
import useDebouncedCallback from 'beautiful-react-hooks/useDebouncedCallback';
import { Dropdown, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const directions = [
    'asc',
    'desc'
]
const initIndexFromString = (val) => {
    if (!val) return 0;
    const idx = directions.indexOf(val);
    return idx > 0 ? idx : 0;
}

const initTypeFromString = (val) => {
    if (!SortingTypes.includes(val)) return "none";
    return val;
}

const SortingCapabilities = {
    "price": "price",
    "modification": "modification",
    "creation": "creation"
};

const getTranslationKey = (sortKind) => `product_list.sorting.by_${sortKind}`

export const SortingTypes = Object.keys(SortingCapabilities);

export const Sorting = ({ defaultSort, onSortChange }) => {
    const [t] = useTranslation();
    const [directionId, setDirectionId] = useState(initIndexFromString(defaultSort?.direction));
    const direction = useMemo(() => directions[directionId], [directionId]);
    const [sortType, setSortType] = useState(initTypeFromString(defaultSort?.type));

    const updateDirection = () => {
        setDirectionId(curr => (curr + 1) % directions.length)
    }

    const callBackSortChange = useDebouncedCallback((direction, sortType) => {
        onSortChange(sortType === "none" ? undefined : { direction, type: sortType });
    }, [onSortChange])

    useEffect(() => {
        if (defaultSort?.direction !== direction || defaultSort?.type !== sortType)
            callBackSortChange(direction, sortType);
    }, [direction, sortType])

    return (
        <>
            <span css={css` color:rgb(77, 77, 77)`}>
                {sortType !== 'none' && (<Icon css={css`cursor: pointer;`} name={`sort content ${direction === 'asc' ? 'descending' : direction === 'desc' ? 'ascending' : null}`} onClick={updateDirection}></Icon>)}
                {sortType === 'none' && (<Icon name='sort content' />)}
                <Dropdown options={[
                    { key: null, value: 'none', text:t('product_list.sorting.no_sort') },
                    { key: 'price', value: SortingCapabilities.price, text:t(getTranslationKey(SortingCapabilities.price))  },
                    { key: 'modification', value: SortingCapabilities.modification, text:t(getTranslationKey(SortingCapabilities.modification))  },
                    { key: 'creation', value: SortingCapabilities.creation,  text:t(getTranslationKey(SortingCapabilities.creation)) },
                ]}
                    value={sortType}
                    defaultValue={sortType}
                    onChange={(e, { value }) => {
                        setSortType(value);
                        if (!value) {
                            setDirectionId(0);
                        }
                    }}
                />
            </span>



        </>
    )
}