/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import useUser from '../../Shared/UserProvider/useUser';
import React, { useEffect, useState } from 'react';
import { exportPosAvailabilitiesOfACompany, getMerchantsOfCompany } from '../../../services/Company/Company.service'
import { Accordion, Button, Divider, Form, Header, Icon, Tab } from 'semantic-ui-react'
import HoursForm from './HoursForm';
import { useTranslation } from 'react-i18next';
import PosDaysOff from './PosDaysOff/PosDaysOff';
import { UltyDropdown } from '@ulty/ulty-ui';
import { notify } from 'react-notify-toast'
import { saveFile } from '../../../services/File/File.service'
import { ExportAllCompanyOpeningButton } from '../../Shared/ExportAllCompanyOpeningButton/ExportAllCompanyOpeningButton';




const AdminHours = () => {
    const [t] = useTranslation();
    const { user } = useUser();
    const [merchants, setMerchants] = useState([]);
    const [selectedMerchant, setSelectedMerchant] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        loadMerchants();
    }, []);

    const loadMerchants = async () => {
        setIsLoading(true);
        const companyId = user.provider.type === 'COMPANY' ? user.provider.object.id : user.provider.object.company.id;
        const merchants = await getMerchantsOfCompany(companyId);
        setMerchants(merchants);
        if(!selectedMerchant){
            setSelectedMerchant(merchants?.length > 0 ? merchants[0] : null);
        }
        setIsLoading(false);
    }


    const handleMerchantChange = (e, { value }) => {
        setSelectedMerchant(merchants.find(m => m.id === value));
    }

    

    return (
        <Tab.Pane>
            <Form>
                {user.provider.type === 'COMPANY' && (
                    <>
                        <ExportAllCompanyOpeningButton />
                        <Divider />
                        <Form.Field>
                            <label>{t('global.merchant')}</label>
                            <UltyDropdown
                                fluid
                                selection
                                loading={isLoading}
                                value={selectedMerchant?.id}
                                name="merchants"
                                search
                                onChange={handleMerchantChange}
                                options={merchants.map(dm => ({
                                    text: dm.name,
                                    value: dm.id,
                                    key: dm.id
                                }))}
                            />
                        </Form.Field>
                        <Divider />
                    </>
                )}
            </Form>

            {selectedMerchant && selectedMerchant.id &&
                <Accordion styled css={css`
                    width: 100% !important;
                `} panels={[{
                    key: `hoursFormAccordion`,
                    title: {
                        content: <Header css={css`display: inline`}>{t('administration.hours.header')}</Header>
                    },
                    content: {
                        content: <HoursForm merchant={selectedMerchant} reload={loadMerchants} />
                    }
                }, {
                    key: 'posDaysOffAccordion',
                    title: {
                        content: <Header css={css`display: inline`}>{t('administration.pos_days_off.pos_days_off')}</Header>
                    },
                    content: {
                        content: <PosDaysOff pos={selectedMerchant} />
                    }
                }]} />
            }
        </Tab.Pane>
    )
}



export default AdminHours;
