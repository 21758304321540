/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {Button, Dropdown, Message, Icon} from 'semantic-ui-react';
import Timer from '../../../Shared/Timer/Timer';
import moment from 'moment';
import OrderDenyReasonSelector from '../../OrderDenyReason/OrderDenyReasonSelector';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery';
import {MOBILE_THRESHOLD} from '../../../../constants';
import {UltyModalWrapperContext} from '../../../Shared/UltyModalWrapper/UltyModalWrapperContext';
import DefinePickupTime from './DefinePickupTime';
import PlatformModule from '../../../../services/Platform/PlatformModule';
import { addSeconds } from 'date-fns';
import humanizeDuration from '../../../../helpers/humanizeDuration';
import { isOrderScheduledInMoreThanXMinutes } from '../../../../services/Order/order.helper';

const statusesBeforeReady = ["RECEIVED","ACCEPTED","PREPARING"];
const orderStillNotReady = (status) => statusesBeforeReady.includes(status ?? "")


const OrderFlowActions = ({
                              order,
                              orderLifetime,
                              isDenying,
                              isDenyAcceptLoading, 
                              onDenyOrder,
                              onAcceptOrder,
                              onMarkAsReady,
                              onFulfillmentIssues,
                              onDenyReasonSubmit
                          }) => {
    const [t] = useTranslation();
    const { handleUltyModalWrapper } = useContext(UltyModalWrapperContext);
    
    const [refreshed,refresh] = useState(null);

    useEffect(() => {
        const timer = setInterval(()=> {
            refresh(new Date())
        }, 60*1000);
        return () => {
            clearInterval(timer)
        }
    },[])


    const isScheduledOrder = useMemo(() => isOrderScheduledInMoreThanXMinutes(order,60),[order,refreshed])

    const timerEndDate = useMemo(() => {
        if(order?.status === "RECEIVED") {
            return moment.utc(order.receivedAt).add(orderLifetime, 's');
        } else if ((orderStillNotReady(order?.status) && order.acceptedAt)
             && order.platform.displayPreparationTimer === "reverse"){
                // Scheduled orders are 
                const upperBound = Math.max(
                    new Date(order.pickupAt),
                    addSeconds(new Date(order.acceptedAt),orderLifetime)
                )
            return new Date(upperBound)
        }
        
        return null;
    }, [order,orderLifetime]);


    const orderFlowDeliverooMessage = useMemo(()=> {
        if(!order
            || order.platform.module !== PlatformModule.DELIVEROO
            || order.platform.displayPreparationTimer !== 'reverse'){
                return undefined;
        }
        if(order.status === 'RECEIVED'){
            return t("order.message.deliveroo_new_flow.on_received")
        }
        if(!isScheduledOrder && orderStillNotReady(order.status)){
            return t("order.message.deliveroo_new_flow.on_not_ready")
        }
    },[order,t,isScheduledOrder])


    const getHumanPickupTime = (pickupAt) => humanizeDuration(pickupAt,new Date(),{round: true, units: ['d', 'h', 'm'],largest:1 })
    const formatScheduledHour = (o) => {
        return  t('order.scheduled_pickup_in', { dt:getHumanPickupTime(o.pickupAt)});
    }

    const headerLabel = useMemo(() => {
        if(!order) return;
        if(order.status === "RECEIVED") {
            if(isScheduledOrder) return formatScheduledHour(order);
                
            return t('order.an_order_has_been_received');

        } else if (orderStillNotReady(order.status)) {
            if(isScheduledOrder) return formatScheduledHour(order);
            return t('order.preparing_time'); 
        }
    },[order,t,isScheduledOrder]);

    const canDeny = useMemo(() => {
        if(!order) return false;

        if(order.platform?.module === PlatformModule.JUST_EATS)
            return false; 
        
        if(order.platform?.module === PlatformModule.DELIVEROO && order.platform.displayPreparationTimer === 'reverse')
            return order.status === 'RECEIVED' || order.status === 'ACCEPTED';

        return order.status === 'RECEIVED';
    },[order]);

    const canModify = useMemo(() => {
        if(order.platform?.module === PlatformModule.DELIVEROO && order.platform.displayPreparationTimer !== 'reverse')
            return order.status === 'RECEIVED' && order?.platform.canEditOrder
        return order?.platform.canEditOrder
    },[order]);

    const canAccept = useMemo(() => {
        return order?.status === 'RECEIVED';
    },[order]);

    const canMarkAsReady = useMemo(() => {
        return order.status !== 'RECEIVED' && orderStillNotReady(order.status)
    },[order])

    const isMobile = useMediaQuery(`(max-width: ${MOBILE_THRESHOLD}px)`);
    const orButtons = isMobile ? css`
      .ui.buttons .or {
        height: .4em;
      }
    ` : css`
      .ui.buttons .or {
        height: auto;
      }
    `;

    const defineDelayPickupTime = () => {
        const modalSettings = {
            size: 'tiny',
            component: <DefinePickupTime onSubmit={onAcceptOrder} />
        };

        handleUltyModalWrapper(true, modalSettings);
    }

    return (
        <>
            <Message color='yellow' css={css`
              display: flex;
              z-index:999!important;
              justify-content: center;
              flex-direction: column;
              align-items: center;`}>
                {orderLifetime && timerEndDate &&
                <Message.Header css={css`
                    font-size:1.6em!important;
                    text-align:center;
                `}>
                    {headerLabel}
                </Message.Header>
                }

                {(orderFlowDeliverooMessage && <Message.Content  css={css`
                text-align:center;
                font-weight: bold;
                color: orange;
                font-size: 1.3em;
                white-space: pre-wrap`}>
                    <br/>
                    <span css={css`color: orange;`}><Icon name="warning circle">&nbsp;</Icon> {t("order.message.deliveroo_new_flow.new")}&nbsp;<Icon name="warning circle"></Icon></span>
                    <br/>
                    <span>{orderFlowDeliverooMessage}</span>
                    
                </Message.Content>)}
                {orderLifetime && timerEndDate && !isScheduledOrder &&
                <Timer endDate={timerEndDate} />
                 }
                
                <div css={css`background-color: white;
                  margin: 1em;
                  ${orButtons}
                `}>
                    <Button.Group
                        vertical={isMobile}
                    >
                        { canDeny && (
                            <>
                                <Button
                                    size='large'
                                    color="red"
                                    loading={isDenyAcceptLoading}
                                    onClick={onDenyOrder}
                                >
                                    {t('order.deny')}
                                </Button>
                                <Button.Or text={t('order.or')} />
                            </>
                        )}
                        { canModify && (
                            <>
                                <Button
                                    size='large'
                                    color="blue"
                                    loading={isDenyAcceptLoading}
                                    onClick={onFulfillmentIssues}
                                >
                                    {t('order.modify')}
                                </Button>
                                <Button.Or text={t('order.or')} />
                            </>
                        )}
                        {(canAccept && (
                        <Button.Group
                            color='green'
                        >
                            <Button
                                size='massive'
                                loading={isDenyAcceptLoading}
                                onClick={() => onAcceptOrder()}
                                css={css`
                                  &.ui.massive.button {
                                    width: auto;
                                  }

                                  border-bottom-left-radius: ${isMobile ? '.28571429rem' : '0'} !important;
                                  border-bottom-right-radius: ${order.platform.module !== PlatformModule.UBER_EATS ? '.28571429rem' : '0'} !important;
                                  border-top-right-radius: ${order.platform.module !== PlatformModule.UBER_EATS && !isMobile ? '.28571429rem' : '0'} !important;
                                  border-top-left-radius: 0 !important;
                                `}
                            >
                                {t('order.accept')}
                            </Button>
                            {order.platform.module === PlatformModule.UBER_EATS &&
                                <Button
                                    size='massive'
                                    icon="stopwatch"
                                    loading={isDenyAcceptLoading}
                                    onClick={defineDelayPickupTime}
                                    css={css`
                                        &.ui.massive.button {
                                            width: auto;
                                        }

                                        margin-left: .2em !important;

                                        border-bottom-left-radius: 0 !important;
                                        border-bottom-right-radius: .28571429rem !important;
                                        border-top-right-radius: ${!isMobile ? '.28571429rem' : '0'} !important;
                                        border-top-left-radius: 0 !important;
                                    `}
                                ></Button>
                            }
                        </Button.Group>))}
                        {(canMarkAsReady && (
                        <Button.Group
                            color='green'
                        >
                            <Button
                                size='massive'
                                loading={isDenyAcceptLoading}
                                onClick={() => onMarkAsReady()}
                                css={css`

                                  border-bottom-left-radius: ${isMobile ? '.28571429rem' : '0'} !important;
                                  border-bottom-right-radius: .28571429rem : '0'} !important;
                                  border-top-right-radius: ${ !isMobile ? '.28571429rem' : '0'} !important;
                                  border-top-left-radius: 0 !important;
                                `}
                            >
                                {t('order.mark_as_ready')}
                            </Button>
                          
                        </Button.Group>))}
                    </Button.Group>
                </div>
            </Message>

            {isDenying &&
                <OrderDenyReasonSelector
                    itemsFromOrder={order.orderItems}
                    isSubmitting={isDenyAcceptLoading}
                    onDenyReasonSubmit={onDenyReasonSubmit}
                />}
        </>
    )
}

export default OrderFlowActions;
