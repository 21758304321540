import React, { useEffect, useState } from 'react'

import { Grid, Header, Form, Button, Message, Icon, Checkbox } from 'semantic-ui-react'
import { Trans, useTranslation } from 'react-i18next'
import { notify } from 'react-notify-toast';
import PropTypes from 'prop-types';

import {
  IbanElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { setupIntentForBilling, validateSetupIntentForBilling } from '../../../services/Billing/Billing.service';
import {UltyInputText} from '@ulty/ulty-ui';

const createOptions = () => {
  return {
    style: {
      base: {
        letterSpacing: '0.025em',
        fontFamily: 'Arial MT',
        color: '#999999',
        fontSize: '18px',
        fontWeight: 400,
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    },
  };
};

const CGU_URL = 'https://ulty-cgu.s3.eu-west-1.amazonaws.com/ULTY+CGU-CGA+-+20211210.pdf';

const AdminBillingForm = ({ billingDetails, reload }) => {
  const [t] = useTranslation();

  const elements = useElements();
  const stripe = useStripe();

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({});
  const [isModifying, setIsModifying] = useState(false);
  const [cguAccepted, setCguAccepted] = useState(false);

  useEffect(() => {
    if (!billingDetails?.customer?.payment_method?.sepa_debit) {
      setIsModifying(true);
    }
  }, [billingDetails]);

  const handleSubmit = async () => {
    if (!cguAccepted) {
      notify.show(t('administration.billing.cgu_mandatory'), 'error');
      return;
    }

    setLoading(true);

    const fields = ['iban'];
    let isError = false;
    for (let i = 0; i < fields.length; i++) {
      let f = fields[i]
      if (typeof form[f] === 'undefined' || form[f].empty || form[f].error || !form[f].complete) {
        isError = true;
        break;
      }
    }

    if (isError) {
      notify.show(t('administration.billing.stripe_error'), 'error');
      setLoading(false);
      return;
    }

    const paymentSrc = {
      type: 'sepa_debit',
      sepa_debit: elements.getElement(IbanElement)
    };

    const setupData = await setupIntentForBilling();
    if (!setupData) {
      setLoading(false)

      notify.show(t('global.anErrorOccurred'), 'error');
      return;
    }

    const result = await stripe.confirmSepaDebitSetup(
      setupData,
      {
        payment_method: {
          ...paymentSrc,
          billing_details: {
            name: billingDetails.name,
            email: billingDetails.email,
            address: {
              postal_code: billingDetails.postalcode,
              country: billingDetails.country,
              city: billingDetails.city,
              line1: billingDetails.address,
            }
          }
        },
      }
    );

    if (typeof result.error !== 'undefined') {
      let err = t('administration.billing.payment.error_setup');
      if (result.error.code === 'setup_intent_authentication_failure') {
        err = t('administration.billing.payment.error_verification');
      } else if (result.error.code === 'card_declined') {
        err = t('administration.billing.payment.error_refused');
      } else if (result.error.code === 'expired_card') {
        err = t('administration.billing.payment.error_card_expired');
      } else if (result.error.code === 'incorrect_cvc') {
        err = t('administration.billing.payment.error_cvc');
      } else if (result.error.code === 'incorrect_number') {
        err = t('administration.billing.payment.error_number');
      }

      notify.show(err, 'error');

      setLoading(false);
      return;
    }

    try {
      await validateSetupIntentForBilling({
        paymentMethod: result.setupIntent.payment_method,
        setupIntent: result.setupIntent.id,
        cguAccepted,
        cguUrl: CGU_URL,
      });

      notify.show(t('administration.billing.success'), 'success');
      await reload();

      setCguAccepted(false);
      setIsModifying(false);
    } catch (e) {
      notify.show(t('global.anErrorOccurred'), 'error');
    }

    setLoading(false);
  }

  const handleChange = (event) => {
    setForm(prev => {
      return {
        ...prev,
        [event.elementType]: event
      };
    });
  }

  return (
    <React.Fragment>
      <Grid className="card-form">
        <Grid.Row>
          <Grid.Column width={16}>
            <Header className="form-header" as='h3' content={t('administration.billing.payment_method_header')} />
          </Grid.Column>
        </Grid.Row>

        { isModifying ? (
          <React.Fragment>
            <Grid.Row className="checkout-form-Grid.row">
              <Grid.Column computer={16}>
                <Form.Field style={{ marginBottom: 0 }}>
                  <label style={{ fontWeight: 'bold' }}>{t('administration.billing.iban')}</label>
                </Form.Field>
                <Form.Field>
                  <IbanElement
                    options={{
                      supportedCountries: ['SEPA'],
                      placeholderCountry: 'FR'
                    }}
                    {...createOptions()} onChange={handleChange} />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="checkout-form-Grid.row">
              <Grid.Column width={16}>
                <Message visible
                  header={t('administration.billing.payment.sepa_header')}
                  list={[
                    t('administration.billing.payment.sepa_line_0'),
                    t('administration.billing.payment.sepa_line_1'),
                    t('administration.billing.payment.sepa_line_2'),
                  ]}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16} textAlign="right">
                <Checkbox
                  toggle checked={cguAccepted}
                  onChange={(evt, { checked }) => setCguAccepted(checked)}
                  label={<label><Trans i18nKey="administration.billing.cgu">J'accepte les <a href={CGU_URL} target="_blank">conditions générales d'utilisation</a> de Local Stores et les conditions tarifaires</Trans></label>}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16} textAlign="right">
                <Button color='teal' onClick={handleSubmit} disabled={loading||!cguAccepted} loading={loading}>{t('administration.billing.save')}</Button>
              </Grid.Column>
            </Grid.Row>
          </React.Fragment>
        ) : (
          <>
            <UltyInputText
              readonly
              label={t('administration.billing.current_iban')}
              value={`${billingDetails?.customer?.payment_method?.sepa_debit?.country} **** ${billingDetails?.customer?.payment_method?.sepa_debit?.last4}`}
            />
            <Icon name="pencil" onClick={() => setIsModifying(true)} style={{
              cursor: 'pointer'
            }} />
          </>
        )}
      </Grid>
    </React.Fragment>
  )
}

AdminBillingForm.propTypes = {
  billingDetails: PropTypes.object.isRequired,
  reload: PropTypes.func.isRequired,
}

export default AdminBillingForm
