import {useLocation} from 'react-router-dom';
import i18n from 'i18next';

export const filtersToQueryString = (filters) => {
    const queryStrings = Object.keys(filters).map(key => {
        let value = filters[key];

        if (value === undefined) {
            return '';
        }

        if (Array.isArray(value)) {
            if(value.length > 0) {
                value=value.join();
            } else if (value.length === 0) {
                return ''
            }
        }

        return `&${key}=${encodeURIComponent(value)}`
    });

    const queryString = queryStrings.join('');
    return queryString.replace('&', '?');
}

export const formatFilterValueFromType = (filterType, filterName, filterValue) => {
    switch (filterType) {
        case 'array':
            return filterValue.split(',').map(val => i18n.t(`global.enums.itemTypes.${val}`)).join(' / ');
        case 'boolean':
            return filterValue[filterValue.raw];
        case 'primitive':
            return filterValue;
        default:
            return '';
    }
}

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}
