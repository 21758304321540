/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { useState, useEffect, useContext } from 'react'

import { Item, Button, Checkbox, Form, Input } from 'semantic-ui-react'
import {UltyModalWrapperContext} from '../../Shared/UltyModalWrapper/UltyModalWrapperContext'

import { MarkupEdit } from './Markups/MarkupEdit'
import { notify } from 'react-notify-toast';
import { useTranslation } from 'react-i18next';
import {patchPosBrandPlatform} from '../../../services/PointOfSale/PointOfSale.service';
import useUser from '../../Shared/UserProvider/useUser';
import { ComingSoon } from '../../Shared/ComingSoon/ComingSoon'

const PlatformItem = ({ posId, brandId, platform }) => {
    const [t] = useTranslation();
    const [form, setForm] = useState({
        active: false,
        autoAcceptOrder: false,
        markup: ''
    })
    const [loading, setLoading] = useState(false);
    const {user} = useUser();
    const { handleUltyModalWrapper } = useContext(UltyModalWrapperContext)


    const handleSubmit = async () => {
        notify.hide()
        setLoading(true);

        try {
            const updatedPosBrandPlatform = await patchPosBrandPlatform({
                posId,
                brandId,
                platformId: platform.id,
                active: form.active,
                autoAcceptOrder: form.autoAcceptOrder,
                markup: form.markup,
            });

            setForm({
                active: updatedPosBrandPlatform.active,
                autoAcceptOrder: updatedPosBrandPlatform.autoAcceptOrder,
                markup: updatedPosBrandPlatform.markup,
            });

            notify.show(t('global.registerSuccess'), 'success');
        } catch {
            notify.show(t('global.anErrorOccurred'), 'error');
        } finally {
            setLoading(false);
        }
    }
    const handleMarkup = async () => {
        const modalSettings = {
                component: <MarkupEdit posBrandPlatform={{posId,brandId,platformId: platform.id}}
                />
            }
    
            handleUltyModalWrapper(true, modalSettings)
    }

    useEffect(() => {
        setForm({
            active: platform.active,
            autoAcceptOrder: platform.autoAcceptOrder,
            markup: platform.markup
        })
    }, [platform]);

    return (
        <Item key={platform.id}>
            <Item.Image src={platform.logo} />

            <Item.Content>
                <Item.Header>{platform.name}</Item.Header>
                <Item.Meta/>
                <Item.Description css={css`
                  padding-top: 1rem;
                `}>
                    <Form>
                        {user.isStaff && (
                          <Form.Field>
                            <Checkbox checked={form.active}
                              label={t('administration.platforms.active')}
                              toggle
                              onChange={(evt, dt) =>
                                setForm(prev => ({ ...prev, active: dt.checked }))} />
                          </Form.Field>
                        )}
                        <Form.Field>
                            <Checkbox checked={form.autoAcceptOrder}
                                      label={t('pos_brand_platform.auto_accept_orders')}
                                      toggle
                                      onChange={(evt, dt) =>
                                          setForm(prev => ({ ...prev, autoAcceptOrder: dt.checked }))} />
                        </Form.Field>
                        <Form.Field
                            control={Input}
                            name="markup"
                            type="number"
                            label={t('administration.platforms.markup')}
                            placeholder='0.00'
                            value={form.markup}
                            onChange={(evt, dt) => setForm(prev => ({ ...prev, markup: parseFloat(dt.value || '0.0') }))}
                        />
                    </Form>
                </Item.Description>
                <Item.Extra>
                    <div style={{ textAlign: 'right' }}>
                        <Button color="teal" type="submit" loading={loading} disabled={loading} onClick={handleSubmit}>{t('global.save')}</Button>
                    </div>
                </Item.Extra>
            </Item.Content>
        </Item>
    )
}

export default PlatformItem
