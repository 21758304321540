import React, { useEffect, useState } from 'react'
import {Tab, Header, Table, Button, Icon, Label} from 'semantic-ui-react';
import { UltyModalWrapperContext } from '../../Shared/UltyModalWrapper/UltyModalWrapperContext';

import UserEdit from './UserEdit'
import useUser from '../../Shared/UserProvider/useUser';
import {useTranslation} from 'react-i18next';
import {getUsersOfCompany} from '../../../services/Company/Company.service';
import {notify} from 'react-notify-toast';
import {getUsersOfMerchant} from '../../../services/PointOfSale/PointOfSale.service';
import { isProviderAPos } from '../../../services/Provider/Provider.service';

const AdminUsers = () => {
  const [t] = useTranslation();
  const {user} = useUser();

  const [loading, setLoading] = useState(true)
  const [users, setUsers] = useState([]);

  const { handleUltyModalWrapper } = React.useContext(UltyModalWrapperContext);
  const defineUserModal = (user) => {
    const modalSettings = {
      title: user ? `${user.firstname} ${user.lastname}` : t('user.edit.new_user'),
      component: <UserEdit
          userId={user?.id}
          afterSubmit={() => {
            loadUsers();
            handleUltyModalWrapper(false, null);
          }}
      />
    };

    handleUltyModalWrapper(true, modalSettings);
  };

  const loadUsers = async () => {
    setLoading(true);
    try {
      let users;
      if (user.provider.type === 'COMPANY') {
        users = await getUsersOfCompany(user.provider.object.id);
      } else {
        users = await getUsersOfMerchant(user.provider.object.id);
      }
      setUsers(users);
    } catch (e) {
      notify.show(`${t(`global.${e.message}`)}`, 'error');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadUsers();
  }, []);

  return (
    <Tab.Pane loading={loading}>
      <Header>{t('administration.users.list')}</Header>

      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t('administration.users.field_type')}</Table.HeaderCell>
            <Table.HeaderCell>{t('administration.users.field_name')}</Table.HeaderCell>
            <Table.HeaderCell>{t('administration.users.field_firstname')}</Table.HeaderCell>
            <Table.HeaderCell>{t('administration.users.field_email')}</Table.HeaderCell>
            <Table.HeaderCell>{t('role.role')}</Table.HeaderCell>
            <Table.HeaderCell>{t('administration.users.field_active')}</Table.HeaderCell>
            <Table.HeaderCell>{t('administration.users.field_actions')}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {(users || []).filter(u => user.provider.type === 'COMPANY' || u.providers.find(p => isProviderAPos(p) && p.id === user.provider.id)).map(u => (
            <Table.Row key={u.id}>
              <Table.Cell>
                <Label color={u.providers.some(p => p.type === 'COMPANY') ? 'blue' : 'green'}>
                  {u.providers.some(p => p.type === 'COMPANY') ? t('provider.type_COMPANY') : t('provider.type_POINT_OF_SALE')}
                </Label>
              </Table.Cell>
              <Table.Cell>{u.lastname}</Table.Cell>
              <Table.Cell>{u.firstname}</Table.Cell>
              <Table.Cell>{u.email}</Table.Cell>
              <Table.Cell>{t(`role.${u.providers.find(p => p.id === user.provider.id)?.role?.name || 'UNKNOWN'}`)}</Table.Cell>
              <Table.Cell textAlign="center">
                {u.active ? (<Icon name="check circle outline" color="green" />) : (<Icon name="times circle outline" color="red" />)}
              </Table.Cell>
              <Table.Cell textAlign="center">
                {!(isProviderAPos(user.provider) && u.providers.some(p => p.type === 'COMPANY')) &&
                  <Button icon='pencil' onClick={() => defineUserModal(u)} />
                }
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <div style={{ textAlign: 'right' }}>
        <Button icon='plus' onClick={() => defineUserModal(null)} />
      </div>
    </Tab.Pane>
  )
}

export default AdminUsers
