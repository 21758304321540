import {call, METHODS} from '../http-client';

const ROUTE_PREFIX = 'providers';

export const getUserOfAMerchant = async (providerId, userId) => {
    try {
        return await call(METHODS.GET, ROUTE_PREFIX, `${providerId}/users/${userId}`);
    } catch (e) {
        console.error(`getUserOfAMerchant error: ${e.message}`);
        throw e;
    }
}

export const setProviderItemInventory = async (providerId, itemId, inventory) => {
    try {
        return await call(METHODS.PUT, ROUTE_PREFIX, `${providerId}/items/${itemId}/inventory`, inventory);
    } catch (e) {
        console.error(`setProviderItemInventory error: ${e}`);
        throw e;
    }
}

function parseItemWithUnavailableUntil(item) {
    return item ? {
        ...item,
        unavailableUntil: item.unavailableUntil === 'Infinity' ? Infinity : item.unavailableUntil
    } : null;
}

export const getItemAvailability = async (providerId, itemId) => {
    const item = await call(METHODS.GET, ROUTE_PREFIX, `${providerId}/items/${itemId}/availability`);

    return parseItemWithUnavailableUntil(item);
}

export const patchItemAvailability = async (providerId, itemId, value) => {
    const item = await call(METHODS.PATCH, ROUTE_PREFIX, `${providerId}/items/${itemId}/availability`, {
        unavailableUntil: value
    });

    return parseItemWithUnavailableUntil(item);
}

export const isQuantityEnabled = (unavailableUntil, uncountable) => {
    return !(unavailableUntil !== null || uncountable);
}

export const isProviderAPos = (provider) => ['POINT_OF_SALE', 'MERCHANT'].includes(provider?.type);