import React, { useEffect, useState } from 'react'
import {Tab, Item, Dropdown, Form, Divider} from 'semantic-ui-react';
import PlatformItem from './PlatformItem'
import useUser from '../../Shared/UserProvider/useUser';
import {getMerchantsOfCompany} from '../../../services/Company/Company.service';
import {getBrandsOfAPos, getPlatformsOfPosBrand} from '../../../services/PointOfSale/PointOfSale.service';
import { useTranslation } from 'react-i18next';

const AdminPlatforms = () => {
    const [t] = useTranslation();
    const {user} = useUser();
    const [pointOfSales, setPointOfSales] = useState([]);
    const [brands, setBrands] = useState([]);
    const [selectedPos, setSelectedPos] = useState(user.getUserPos());
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [platforms, setPlatforms] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        (async function loadPointOfSales() {
            if (user.provider.type === 'COMPANY') {
                setIsLoading(true);
                const poss = await getMerchantsOfCompany(user.companyId);
                setPointOfSales(poss);
                setSelectedPos(poss?.length > 0 ? poss[0] : null);
                setIsLoading(false);
            }
        })();
    }, [user.companyId, user.provider.type]);

    useEffect(() => {
        (async function loadBrands() {
            const posId = user.provider.type === 'COMPANY' ? selectedPos?.id : user.provider.object.id;
            if (posId) {
                setIsLoading(true);
                const posBrands = await getBrandsOfAPos(posId);
                setBrands(posBrands);
                setSelectedBrand(posBrands?.length > 0 ? posBrands[0] : null);
                setIsLoading(false);
            }
        })();
    }, [selectedPos?.id, user.provider.object.id, user.provider.type]);

    useEffect(() => {
        (async function loadPlatforms() {
            const posId = user.provider.type === 'COMPANY' ? selectedPos?.id : user.provider.object.id;
            if (posId && selectedBrand?.id) {
                const posBrandPlatforms = await getPlatformsOfPosBrand(posId, selectedBrand.id);
                setPlatforms(posBrandPlatforms.platforms);
            }
        })();
    }, [selectedBrand?.id, selectedPos?.id, user.provider.type]);

    const handlePosChange = (e, { value }) => {
        setSelectedPos(pointOfSales.find(pos => pos.id === value));
    }

    const handleBrandChange = (e, {value}) => {
        setSelectedBrand(brands.find(brand => brand.id === value));
    }

    return (
        <Tab.Pane>
            <Form>
                <Form.Group widths="equal">
                    {user.provider.type === 'COMPANY' &&
                        <Form.Field>
                            <label>{t('global.merchant')}</label>
                            <Dropdown
                                fluid
                                selection
                                loading={isLoading}
                                value={selectedPos?.id}
                                name="merchants"
                                onChange={handlePosChange}
                                options={pointOfSales.map(dm => ({
                                    text: dm.name,
                                    value: dm.id,
                                    key: dm.id
                                }))}
                            />
                        </Form.Field>
                    }

                    <Form.Field>
                        <label>{t('global.brand')}</label>
                        <Dropdown
                            fluid
                            selection
                            loading={isLoading}
                            value={selectedBrand?.id}
                            name="brands"
                            onChange={handleBrandChange}
                            options={brands.map(brand => ({
                                text: brand.name,
                                value: brand.id,
                                key: brand.id
                            }))}
                        />
                    </Form.Field>
                </Form.Group>
            </Form>
            <Divider horizontal />
            <Item.Group divided>
                {platforms.map(platform => <PlatformItem key={platform.id} posId={user.provider.type === 'COMPANY' ? selectedPos?.id : user.provider.object.id} brandId={selectedBrand.id} platform={platform} />)}
            </Item.Group>
        </Tab.Pane>
    )
}

export default AdminPlatforms
