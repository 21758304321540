import {rawCall, call, METHODS, withIdempotencyKey} from '../http-client';

const ROUTE_PREFIX = 'metrics';

export const getMetricsTop = async ({dateRange, merchantId, brandIds, platformIds},cancellationSource) => {
  return await call(METHODS.POST, ROUTE_PREFIX, "top", {
    sd: dateRange.startDate,
    ed: dateRange.endDate,
    brandIds,
    merchantId,
    platformIds,
  },
  cancellationSource?.patchAxiosOptions(withIdempotencyKey()))
}

export const getMetricsOrders = async ({dateRange, merchantId, brandIds, platformIds, dateComparison},cancellationSource) => {
  return await call(METHODS.POST, ROUTE_PREFIX, "orders", {
    sd: dateRange.startDate,
    ed: dateRange.endDate,
    brandIds,
    merchantId,
    platformIds,
    sdc: dateComparison?.startDate||null,
    edc: dateComparison?.endDate||null,
  },
  cancellationSource?.patchAxiosOptions(withIdempotencyKey()))
}

export const getMetricsToday = async ({dateRange, merchantId, brandIds, platformIds},cancellationSource) => {
  return await call(METHODS.POST, ROUTE_PREFIX, "today", {
    sd: dateRange.startDate,
    ed: dateRange.endDate,
    brandIds,
    merchantId,
    platformIds,
  },
  cancellationSource?.patchAxiosOptions(withIdempotencyKey()))
}

export const getMissedMetricsAnalysis = async ({dateRange, merchantId, brandsIds, platformIds},cancellationSource) => {
  return await call(METHODS.POST, ROUTE_PREFIX, "missed-analysis", {
    sd: dateRange.startDate,
    ed: dateRange.endDate,
    brandsIds,
    merchantId,
    platformIds
  },
  cancellationSource?.patchAxiosOptions(withIdempotencyKey()))
}


export const getMissedMetrics = async ({dateRange, merchantId, brandIds, platformIds}, cancellationSource) => {
  return await call(METHODS.POST, ROUTE_PREFIX, "missed", {
    sd: dateRange.startDate,
    ed: dateRange.endDate,
    brandIds,
    merchantId,
    platformIds
  },
  cancellationSource?.patchAxiosOptions(withIdempotencyKey()))
}

export const getDetailedMetrics = async ({dateRange, merchantId, brandIds, platformIds},cancellationSource) => {
  return await call(METHODS.POST, ROUTE_PREFIX, "detailed", {
    sd: dateRange.startDate,
    ed: dateRange.endDate,
    brandIds,
    merchantId,
    platformIds
  },
  cancellationSource?.patchAxiosOptions(withIdempotencyKey()))
}

export const getDeploymentMetrics = async ({merchantId, brandIds, platformIds},cancellationSource) => {
  return await call(METHODS.POST, ROUTE_PREFIX, "deployments", {
    brandIds,
    merchantId,
    platformIds
  },
  cancellationSource?.patchAxiosOptions(withIdempotencyKey()))
}

export const getPausedStores = async ({merchantId, brandIds, platformIds},cancellationSource) => {
  return await call(METHODS.POST, ROUTE_PREFIX, "paused", {
    brandIds,
    merchantId,
    platformIds
  },
  cancellationSource?.patchAxiosOptions(withIdempotencyKey()))
}

export const downloadMetrics = async (dateRange, merchantId, brands, platformIds, type) => {
  try {
      return await rawCall(METHODS.POST, `${ROUTE_PREFIX}`, `export/${type.toLowerCase()}`, {
        sd: dateRange.startDate,
        ed: dateRange.endDate,
        brandIds: brands,
        merchantId,
        platformIds,
      }, {
        responseType: 'arraybuffer',
        ...withIdempotencyKey()
      });
  } catch (e) {
      console.error(`downloadMetrics error: ${e.message}`);
      throw e;
  }
}