/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import {Fragment, useState, useEffect} from 'react';
import {Grid, Form, Input, Button, Icon, Checkbox, Table} from 'semantic-ui-react';
import { notify } from 'react-notify-toast';
import useUser from '../../Shared/UserProvider/useUser';

import { useTranslation } from 'react-i18next';
import {saveUser} from '../../../services/User/User.service';
import RoleSelector from '../../Role/RoleSelector';
import MerchantSelector from '../../Merchant/MerchantSelector';
import {getUserOfAMerchant, isProviderAPos} from '../../../services/Provider/Provider.service';

const UserEdit = ({ userId, afterSubmit }) => {
  const [t] = useTranslation();
  const {user: currentUser} = useUser();
  const [loading, setLoading] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [canAddUserProvider, setCanAddUserProvider] = useState(false);

  const [form, setForm] = useState({
    firstname: '',
    lastname: '',
    email: '',
    providers: [],
    active: false,
    addToCompany: false,
    companyRole: null,
  });

  const userCompanyId = currentUser.provider.type === 'COMPANY' ?
      currentUser.provider.object.id :
      currentUser.provider.object.company.id;

  useEffect(() => {
    const loadUser = async (userId) => {
      if (!userId) {
        return;
      }

      try {
        setLoading(true);
        const user = await getUserOfAMerchant(currentUser.provider.id, userId);
        setForm(user);
        if (isProviderAPos(currentUser.provider)) {
          setSelectedMerchant(currentUser.provider.object)
        }

        const [companyProvider] = user.providers.filter(p => p.type === 'COMPANY');
        if (companyProvider) {
          setForm(prev => ({
            ...prev,
            addToCompany: true,
            companyRole: companyProvider.role,
          }));
        }
      } catch (e) {
        notify.show(`${t(`global.${e.message}`)}`, 'error');
      } finally {
        setLoading(false);
      }
    }

      loadUser(userId);
  }, [currentUser.provider.id, currentUser.provider.object, currentUser.provider.type, t, userId]);

  useEffect(() => {
    const isAlreadyAdded = form.providers
        .some(provider =>
            provider.object.id === selectedMerchant?.id ||
            (provider.object.id === selectedMerchant?.id &&
            provider.role === selectedRole)
        );

    setCanAddUserProvider(!!selectedMerchant?.id && !!selectedRole && !isAlreadyAdded);
  }, [form.providers, selectedMerchant, selectedRole]);


  const handleChange = (evt, {name, value, checked}) => {
    if (['active', 'addToCompany'].includes(name)) {
      value = checked
    }
    setForm(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const handleSubmit = async () => {
    if (form.email === '' || form.firstname === '' || form.lastname === '') {
      notify.show(t('administration.users.all_fields_mandatory'), 'error');
      return;
    }

    if (form.providers.length === 0 && (!form.addToCompany || isProviderAPos(currentUser.provider))) {
      notify.show(t('administration.users.providerd_mandatory_if_not_company'), 'error');
      return;
    }

    if (form.addToCompany && !form.companyRole) {
      notify.show(t('administration.users.company_role_mandatory'), 'error');
      return;
    }

    if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(form.email)) {
      notify.show(t('administration.users.invalid_email'), 'error');
      return;
    }

    setLoading(true);

    try {
      await saveUser(form);

      if (form?.id) {
        notify.show(t('administration.users.user_updated'), 'success');
      } else {
        notify.show(t('administration.users.user_validated'), 'success');
      }

      handleClose();
    } catch (e) {
      if (e.message === 'email_already_exists') {
        notify.show(t('administration.users.user_already_exists'), 'error');
      } else {
        notify.show(t('global.anErrorOccurred'), 'error');
      }
    } finally {
      setLoading(false);
    }
  }

  const handleClose = () => {
    if (loading) {
      return
    }
    afterSubmit();
  }

  const addProviderUser = () => {
    setForm(prev => ({
      ...prev,
      providers: prev.providers.concat(...[{
        id: selectedMerchant.providerId,
        type: 'POINT_OF_SALE',
        object: {
          id: selectedMerchant.id,
          name: selectedMerchant.name
        },
        role: selectedRole
      }])
    }));

    setSelectedMerchant(null);
    setSelectedRole(null);
  }

  const removeProviderUser = (providerUser) => {
    const providers = form.providers.filter(pu =>
        !(pu.id === providerUser.id && pu.role === providerUser.role)
    );

    setForm(prev => ({
      ...prev,
      providers
    }));
  }

  const updateProviderRole = (providerUser, role) => {
    const providers = [...form.providers];

    const prov = providers.find(p => p.id === providerUser.id);
    Object.assign(prov, { role });

    setForm(prev => ({
      ...prev,
      providers
    }));
  }

  return (
      <Fragment>
        <Form>
          <Form.Field style={{display: form.id ? 'block' : 'none'}}>
            <Checkbox toggle label={t('administration.users.active')} onChange={handleChange} checked={form.active} name="active"
                      disabled={form.id === currentUser.id}/>
          </Form.Field>

          <Form.Field
              control={Input}
              name="firstname"
              value={form.firstname}
              onChange={handleChange}
              label={t('user.edit.firstname')}
              placeholder={t('user.edit.firstname')}
          />
          <Form.Field
              control={Input}
              name="lastname"
              value={form.lastname}
              onChange={handleChange}
              label={t('user.edit.lastname')}
              placeholder={t('user.edit.lastname')}
          />
          <Form.Field
              control={Input}
              name="email"
              value={form.email}
              onChange={handleChange}
              label={t('user.edit.email')}
              placeholder={t('user.edit.email')}
          />

          { currentUser.provider.type === 'COMPANY' && (
            <Fragment>
              <Form.Field>
                <Checkbox toggle label={t('user.edit.add_company_access')} onChange={handleChange} name="addToCompany" checked={form.addToCompany} />
              </Form.Field>

              { form.addToCompany && (
                <Form.Field>
                  <RoleSelector
                    selectedRole={form.companyRole}
                    onRoleChange={(role) => setForm(prev => ({
                      ...prev,
                      companyRole: role,
                    }))} />
                </Form.Field>
              )}
            </Fragment>
          )}

          <Fragment>
            <Form.Field>
              <label style={{fontWeight: 'bold'}}>{t('user.edit.authorize_access_to_pos')}</label>
            </Form.Field>

            <Grid>
              <Grid.Row>
                <Grid.Column width={7}>
                  <MerchantSelector
                      companyId={userCompanyId}
                      selectedMerchant={selectedMerchant}
                      onMerchantChange={setSelectedMerchant}
                  />
                </Grid.Column>

                <Grid.Column width={7}>
                  <RoleSelector
                      selectedRole={selectedRole}
                      onRoleChange={setSelectedRole}/>
                </Grid.Column>
                <Grid.Column width={2} textAlign={'right'}>
                  <Button
                      icon={'plus'}
                      color={'teal'}
                      disabled={!canAddUserProvider}
                      onClick={addProviderUser}
                  />
                </Grid.Column>
              </Grid.Row>

              {form.providers.length > 0 &&
              <Grid.Row css={css`padding: 0 1rem !important;`}>
                <Table size={'small'}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>{t('provider.type_POINT_OF_SALE')}</Table.HeaderCell>
                      <Table.HeaderCell>{t('role.role')}</Table.HeaderCell>
                      <Table.HeaderCell/>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {form.providers
                        .filter(provider => isProviderAPos(provider))
                        .map((providerUser, idx) => (
                        <Table.Row key={`${providerUser.id}`}>
                          <Table.Cell>
                              <MerchantSelector
                                  companyId={userCompanyId}
                                  selectedMerchant={providerUser.object}
                                  onMerchantChange={setSelectedMerchant}
                              />
                          </Table.Cell>
                          <Table.Cell>
                            <RoleSelector
                                selectedRole={providerUser.role}
                                onRoleChange={(role) => updateProviderRole(providerUser, role)}/>
                          </Table.Cell>
                          <Table.Cell textAlign={'right'}>
                            {form.providers.length > 1 &&
                            <Button
                                icon={'times'}
                                color={'red'}
                                onClick={() => removeProviderUser(providerUser)}
                            />
                            }
                          </Table.Cell>
                        </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Grid.Row>
              }
            </Grid>
          </Fragment>

          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <div style={{textAlign: 'right', marginTop: '10px'}}>
                  <Button loading={loading} disabled={loading} color='red' onClick={handleClose}>
                    <Icon name='remove'/>
                    {t('global.cancel')}
                  </Button>
                  <Button loading={loading} disabled={loading} color='teal' onClick={handleSubmit}>
                    <Icon name='checkmark'/>
                    {t('global.validate')}
                  </Button>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Fragment>
  )
}

export default UserEdit
