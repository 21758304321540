/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Popup} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { createElement,useCallback, useMemo } from 'react'
import useFeature from '../FeatureProvider/useFeature';
export const ComingSoon = ({
    as = 'div',
    hide = false,
    featureGate = undefined,
    children,
    disabledOpacity = 0.3,
}) => {

    const {isEnabled} = useFeature();

    const [t] = useTranslation();

    const isDisabled = useMemo(() => (!featureGate || (featureGate && !isEnabled(featureGate))),
    [isEnabled,featureGate]);

    const disableEvent = useCallback(
        (e) => {
          e.persist();
            e.preventDefault();
            e.stopPropagation();
        },
        []
      );

return (<>
{isDisabled && !hide &&
    <Popup
    trigger={
      <div css={css`
      display:inline-block;
      `}>
            {
                createElement(as,{
                    style: {
                        display:'inline-block',
                        transition: 'all 100ms linear',
                          opacity: disabledOpacity,
                          pointerEvents: 'none',
                          userSelect: 'none',
                      },
                      onClick: disableEvent,
                      onKeyDown: disableEvent,
                        tabIndex: -1,
                        'aria-hidden': 'true',
                    },
                    children 
                )
            }
      </div>
    }
    content={t("global.coming_soon")}
    position='top center'
    on={['hover','click']}></Popup>
}
{!isDisabled && !hide && children

}


</>)




}
