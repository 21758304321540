import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import fr from './fr.json'
import en from './en.json'

i18n
  .use(LanguageDetector)
  .init({
    resources: {
      fr: { translations: fr},
      en: { translations: en},
    },
    fallbackLng: 'fr',
    debug: false,

    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: '.',

    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },

    react: {
      useSuspense: true
    },

    detection: {
      order: ['localStorage', 'navigator'],

      lookupLocalStorage: 'ulty-lng',

      caches: ['localStorage'],
      excludeCacheFor: ['cimode'],

      checkWhitelist: true,
      checkForSimilarInWhitelist: false,
    }
  });

export default i18n;
