/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { useEffect, useState } from 'react';

import { Segment, Button } from 'semantic-ui-react';
import {useTranslation} from 'react-i18next';

import Chart from "react-apexcharts";
import ApexCharts from 'apexcharts';

import {unitFormat} from '../../helpers/money';
import useUser from '../Shared/UserProvider/useUser';
import {customTooltip} from './apexChartUtils';
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery';
import { MOBILE_THRESHOLD } from '../../constants';
import moment from 'moment';

const DailyChart = ({ loading, data, dataComparison, range }) => {
  const [t] = useTranslation();
  const {user}= useUser();

  const [timebucket, setTimebucket] = useState(data?.length > 90 ? "MONTH" : data?.length > 31 ? 'WEEK' : 'DAY');
  const isMobile = useMediaQuery(`(max-width: ${MOBILE_THRESHOLD}px)`);

  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);
  const [version, setVersion] = useState(0);

  useEffect(() => {
    const optimalTimeBucket = data?.length > 90 ? "MONTH" : data?.length > 31 ? 'WEEK' : 'DAY';
    if(timebucket !== optimalTimeBucket){
      setTimebucket(optimalTimeBucket);
    }
  },[data.length])


  const buildTimedData = (data, timebucket, range = 0) => {
    const aggData = {};
    data.forEach((d) => {
      const dt = new Date(d.dt);

      switch (timebucket) {
        case 'WEEK':
          const distance = (7 - ((8 - dt.getUTCDay()) % 7)) % 7;
          dt.setUTCDate(dt.getUTCDate() - distance);
          break;

        case 'MONTH':
          dt.setUTCDate(1);
          break;

        default:
          break;
      }

      const key = dt.getTime() + range * 86400000;
      aggData[key] = [
        (aggData[key] || [0, 0])[0] + d.nborder,
        (aggData[key] || [0, 0])[1] + d.revenues,
      ];
    });

    return Object.entries(aggData).map((d) => [parseInt(d[0]), d[1]]);
  }

  const adjustMinMax = (data, min, max, timebucket) => {
    const results = [];

    let tmp = min;
    while(tmp <= max) {
      const tmpMin = tmp;
      const [dt] = data.filter(d => d[0] === tmpMin);

      if (!dt) {
        results.push([ tmp, [0, 0.0] ]);
      } else {
        results.push(dt);
      }

      switch(timebucket) {
        case 'DAY':
          tmp += 86400000;
          break;

        case 'WEEK':
          tmp += 86400000*7;
          break;

        case 'MONTH':
          const mt = (new Date(tmp));
          mt.setUTCMonth(mt.getUTCMonth()+1);
          tmp = mt.getTime();
          break;

        default:
          break;
      }
    }

    return results;
  }

  const buildData = (data, timebucket, range = 0) => {
    const datas = buildTimedData(data, timebucket, range).sort((a, b) => b[0] - a[0]);
    if (datas.length === 0) {
      return {
        results: [],
        period: '',
      };
    }

    const min = datas[datas.length - 1][0];
    const max = datas[0][0];

    const period = `${(new Date(min)).toISOString().split('T')[0]} - ${(new Date(max)).toISOString().split('T')[0]}`;
    const results = adjustMinMax(datas, min, max, timebucket);

    return {
      results,
      period,
      min,
      max,
    }
  }

  const getChartOptions = (isComparisonEnabled) => {
    return isComparisonEnabled ? {
      stroke: {
        colors: ['transparent', 'transparent', '#334d5c', '#334d5c'],
        width: [4, 4, 3, 3],
        dashArray: [0, 0, 4, 0]
      },  
      fill: {
        type: ['pattern', 'color', 'color', 'color'],
        pattern: {
          style: 'slantedLines',
          width: 5,
          height: 5,
          strokeWidth: 1
        }
      }, 
      colors: [
        '#096b58', '#45b29d', '#334d5c', '#334d5c'
      ],  
      yaxis: [
        {
          seriesName: `${t('home.revenues')}`,
          title: {
            text: t('home.revenues')
          }
        },
        {
          seriesName: `${t('home.revenues')}`,
          show: false,
        },
        {
          opposite: true,
          labels: {
            formatter: function(val) {
              return isNaN(val) ? val : val.toFixed(0)
            }
          },
          seriesName: `${t('home.order_count')}`,
          title: {
            text: t('home.order_count')
          },
        },
        {
          seriesName: `${t('home.order_count')}`,
          show: false,
          opposite: true
        },
      ]
    } : {
      stroke: {
        colors: ['transparent', '#334d5c'],
        width: [4, 3],
        dashArray: [0, 0]
      },  
      fill: {
        type: ['color', 'color'],
        pattern: {
          style: 'slantedLines',
          width: 5,
          height: 5,
          strokeWidth: 2
        }
      }, 
      colors: [
        '#45b29d', '#334d5c'
      ],  
      yaxis: [
        {
          title: {
            text: t('home.revenues')
          }
        },
        {
          opposite: true,
          title: {
            text: t('home.order_count')
          },
        },
      ]
    }
  }

  const isPeriodComplete = (timebucket, sd, ed) => {
    switch(timebucket) {
      case 'DAY':
        return true;

      case 'WEEK':
        return sd.isoWeekday() === 1 && ed.isoWeekday() === 7;

      case 'MONTH':
        const endofmonth = ed.clone().endOf('month').date();
        return sd.date() === 1 && ed.date() === endofmonth;

      default:
        return true;
    }
  }

  const comparaisonTooltip = (getTitle, w, dataPointIndex, getHtmlValue, disclaimer) => {
    const comparisonSeries = w.config.series.filter(serie => serie.data.length > 0);
    const htmlSeries = [];

    for (let i = 0; i < comparisonSeries.length; i+=2) {

        const previousPeriod = comparisonSeries?.[i];
        const currentPeriod = comparisonSeries?.[i+1];

        const markerColor = w.config.colors[i];

        const diff = parseFloat(((currentPeriod?.data?.[dataPointIndex]?.[1] || 0) - (previousPeriod?.data?.[dataPointIndex]?.[1] || 0)).toFixed(2));
        const percent = (previousPeriod?.data?.[dataPointIndex]?.[1] || 0) ? (diff/ (previousPeriod?.data?.[dataPointIndex]?.[1] || 0) * 100.0) : 'N/A';

        htmlSeries.push(`
        <tr>
            <td class="apexcharts-tooltip-series-group apexcharts-active" style="order: ${i}; display: flex">
                <span class="apexcharts-tooltip-marker" style="background-color: ${markerColor}"></span>
                <span class="apexcharts-tooltip-text-y-label">${currentPeriod.name}: </span>        
            </td>
            <td style="text-align: right;">
                <span class="" style="font-weight: normal !important;">${getHtmlValue(comparisonSeries.map(serie => serie.data.map(i => i[1])), i, comparisonSeries[i].dataType, '')}</span>        
            </td>
            <td style="text-align: right;">
                <span class="apexcharts-tooltip-text-y-value">${getHtmlValue(comparisonSeries.map(serie => serie.data.map(i => i[1])), i + 1, comparisonSeries[i+1].dataType)}</span>        
            </td>
            <td>
                <span class="apexcharts-tooltip-text-y-value" style="color: ${diff < 0 ? '#db2828' : '#21ba45'};"><i aria-hidden="true" class="${diff < 0 ? 'red caret down' : 'green caret up'} mini icon" style="top: 4px; font-size: 1.4rem !important;"></i>${isNaN(percent) ? 'N/A' : `${diff >= 0 ? '+' : ''}${percent.toFixed(2)}%`} </span>     
            </td>
        </tr>
        `);
    }

    return (`
        <div class="apexcharts-tooltip-title">${t('home.daily_activity')}</div>
        <table>
            <thead>
                <tr>
                    <th></th>
                    <th>${getTitle(moment(comparisonSeries[1].data[dataPointIndex]?.[0]).utc().add(-1 * range, 'days').toDate().getTime())}</th>
                    <th>${getTitle(comparisonSeries[1].data[dataPointIndex]?.[0])}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                ${htmlSeries.join('')}
            </tbody>
        </table>
        ${disclaimer ? `<div style="display: flex; flex-direction: row; align-items: flex-start; justify-content: flex-start; padding: .6rem;"><i aria-hidden="true" class="info circle icon" style="font-size: 1rem !important;"></i><p>${disclaimer}</p></div>` : ''}
    `);
  }


  useEffect(() => {
    const isComparisonEnabled = dataComparison !== null;

    const { results } = buildData(data, timebucket);

    let disclaimer = null, min, max;
    if (data && data.length > 0) {
      max = moment(data[0].dt);
      min = moment(data[data.length - 1].dt);
      disclaimer = isPeriodComplete(timebucket, min, max) ? null : t(`home.range_disclaimer_${timebucket.toLowerCase()}`); 
    }

    setOptions({
      dataLabels: {
        enabled: false
      },
      chart: {
        id: 'chart-0',
        type: 'line',
        zoom: {
          enabled: false
        },
        events: {
          mounted: function(chartContext, config) {
            ApexCharts.exec(`chart-0`, "toggleSeries", `${t('home.order_count')}`);
            if (isComparisonEnabled) {
              ApexCharts.exec(`chart-0`, "toggleSeries", `${t('home.order_count')}-prev`);
            }
          },
          legendClick: (chartContext, seriesIndex, config) => {
            if (seriesIndex === 0) {
              ApexCharts.exec(`chart-0`, "toggleSeries", `${t('home.revenues')}`);
              if (isComparisonEnabled) {
                ApexCharts.exec(`chart-0`, "toggleSeries", `${t('home.revenues')}-prev`);
              }
            } else {
              ApexCharts.exec(`chart-0`, "toggleSeries", `${t('home.order_count')}`);
              if (isComparisonEnabled) {
                ApexCharts.exec(`chart-0`, "toggleSeries", `${t('home.order_count')}-prev`);
              }
            }
          }
        }
      },
      xaxis: {
        type: 'datetime',
        bucket: timebucket,
      },
      legend: {
        show: true,
        markers: {
          fillColors: ['#45b29d', '#45b29d', '#334d5c', '#334d5c'],
          onClick: (chartContext, seriesIndex, config) => {
            if (seriesIndex === 0) {
              ApexCharts.exec(`chart-0`, "toggleSeries", `${t('home.order_count')}`)
              ApexCharts.exec(`chart-0`, "toggleSeries", `${t('home.order_count')}-prev`)
            } else {
              ApexCharts.exec(`chart-0`, "toggleSeries", `${t('home.revenues')}`)
              ApexCharts.exec(`chart-0`, "toggleSeries", `${t('home.revenues')}-prev`)
            }
          }
        },
        onItemClick: {
          toggleDataSeries: false,
        },
        onItemHover: {
          highlightDataSeries: false
        },
        formatter: function(seriesName, opts) {
          return seriesName.replace("-prev","");
      }
      },
      tooltip: {
        shared: false,
        intersect: true,
        custom: function({ series, seriesIndex, dataPointIndex, w }) {
          return customTooltip({ t, range, series, seriesIndex, dataPointIndex, w, currencyCode: user.getCompanyCurrency(), locale: user.getCompanyLocale(), isComparison: isComparisonEnabled ? comparaisonTooltip : null, disclaimer });
        }
      },
      title: {
        text: t('home.daily_activity'),
        align: 'center'
      },
      ...(min && max ? {
        subtitle: {
          text: t('home.daily_activity_subtitle', {
            sd: min.format(moment.localeData().longDateFormat('L')),
            ed: max.format(moment.localeData().longDateFormat('L'))
          }),
          align: 'center',
          offsetY: 20
        },
      } : {}),
      ...getChartOptions(isComparisonEnabled),
    });

    if (isComparisonEnabled) {
      const { results: resultsComparison } = buildData(dataComparison, timebucket, range);

      setSeries([{
        name: `${t('home.revenues')}-prev`,
        data: resultsComparison.map(d => [d[0], unitFormat(d[1][1], user.getCompanyCurrency())]),
        dataType: 'CURRENCY',
        type: 'bar'
      }, {
        name: `${t('home.revenues')}`,
        data: results.map(d => [d[0], unitFormat(d[1][1], user.getCompanyCurrency())]),
        dataType: 'CURRENCY',
        type: 'bar'
      }, {
        name: `${t('home.order_count')}-prev`,
        data: resultsComparison.map(d => [d[0], d[1][0]]),
        dataType: 'NUMERIC',
        type: 'line'
      }, {
        name: `${t('home.order_count')}`,
        data: results.map(d => [d[0], d[1][0]]),
        dataType: 'NUMERIC',
        type: 'line',
      }]);
    } else {
      setSeries([{
        name: `${t('home.revenues')}`,
        data: results.map(d => [d[0], unitFormat(d[1][1], user.getCompanyCurrency())]),
        dataType: 'CURRENCY',
        type: 'bar'
      }, {
        name: `${t('home.order_count')}`,
        data: results.map(d => [d[0], d[1][0]]),
        dataType: 'NUMERIC',
        type: 'line',
      }]);
    }

    setVersion(version => version + 1);
  }, [data, timebucket, dataComparison, range]);

  return (
    <>
      <Segment css={css`
          max-height: 300px;

          .apexcharts-legend-series {
            cursor: pointer !important;
          }
          
          div.apexcharts-legend-series[rel="2"], div.apexcharts-legend-series[rel="4"] {
            ${dataComparison !== null ? 'display: none;' : ''}
          }
        `}>
        {!loading && data && data.length > 0 && (
          <>
            <Button.Group
              toggle
              className="time-toggle"
              style={{
                [isMobile ? "bottom" : "top"]: isMobile ? "0" : "1rem",
                width: isMobile ? "100%" : "auto",
                position: isMobile ? "relative" : "absolute",
                zIndex: 100
              }}
              size="mini"
              compact
            >
              <Button
                size="mini"
                onClick={() => setTimebucket("DAY")}
                toggle
                color={timebucket === "DAY" && "teal"}
                className="btn-time"
              >
                {t("home.analytics.day")}
              </Button>
              <Button
                size="mini"
                onClick={() => {
                  setTimebucket("WEEK");
                }}
                toggle
                color={timebucket === "WEEK" && "teal"}
                className="btn-time"
              >
                {t("home.analytics.week")}
              </Button>
              <Button
                size="mini"
                onClick={() => setTimebucket("MONTH")}
                toggle
                color={timebucket === "MONTH" && "teal"}
                className="btn-time"
              >
                {t("home.analytics.month")}
              </Button>
            </Button.Group>
            <Chart
              key={version}
              options={options}
              series={series}
              type="line"
              height={250}
            />
          </>
        )}
      </Segment>
    </>
  );
}

export default DailyChart
