import React from 'react'

import { Switch, Route } from 'react-router-dom'
import ExtraEdit from './ExtraEdit';


const Extras = () => {
    return (
        <Switch>
            <Route path="/extras/edit/:id" component={ExtraEdit} />
            <Route path="/extras/edit" component={ExtraEdit} />
        </Switch>
    )
}

export default Extras
