/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import {useCallback, useEffect, useState} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

const timerStyles = css`
  line-height: 5rem;
  font-size: 5rem;
`;

const halfTimeStyles = css`
  color: orange;
`;

const nearEndStyles = css`
  color: red;
  font-weight: bolder;
  animation: blink 1s linear infinite;

  @keyframes blink {
    50% {
      opacity: 0;
    }
`;

const Timer = ({endDate = moment()}) => {
    const now = moment().utc();
    const duration = moment.duration(moment(endDate).diff(now)).asMilliseconds();
    const initialDuration = duration  > 0 ? duration : 0;
    const [timeLeft, setTimeLeft] = useState(initialDuration);
    const [initialTimeLeft] = useState(initialDuration);

    useEffect(() => {
        const tick = 1000;
        const interval = setInterval(() => {
            if (timeLeft > 0) {
                setTimeLeft(prevState => {
                    const newTime = prevState - tick;
                    return newTime > 0 ? newTime : 0;
                });
            }
        }, tick);

        return () => clearInterval(interval);
    }, [timeLeft]);

    const padWithZero = (timeLeft) => {
        Object.keys(timeLeft).forEach(key => {
            timeLeft[key] = `${timeLeft[key]}`.padStart(2, '0');
        });
    }

    const formattedTimeLeft = useCallback(() => {
        const formatted = {
            minutes: Math.floor((timeLeft / 1000 / 60) % 60),
            seconds: Math.floor((timeLeft / 1000) % 60),
        };

        padWithZero(formatted);

        return formatted;
    }, [timeLeft]);

    function getTimerStyles() {
        const halfTime = initialTimeLeft / 2;
        const twoMinutes = 120000;

        if (timeLeft <= twoMinutes) {
            return nearEndStyles;
        }

        if (timeLeft <= halfTime) {
            return halfTimeStyles;
        }

        return '';
    }


    return (
        <div
            css={css`
              ${timerStyles}
              ${getTimerStyles()}
            `}>
            {formattedTimeLeft().minutes}:{formattedTimeLeft().seconds}
        </div>
    )
}

Timer.propTypes = {
    endDate: PropTypes.oneOf(PropTypes.instanceOf(Date),PropTypes.instanceOf(moment))
}

export default Timer;
