/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React, {useContext, useEffect, useState} from 'react';
import {Button, Checkbox, Form, Grid, Icon, TextArea, Segment} from 'semantic-ui-react';
import {useTranslation} from 'react-i18next';
import Uploader from '../Uploader';
import {notify} from 'react-notify-toast';
import {getExtra, saveExtra} from '../../services/Extra/Extra.service';
import {archive, isItemProvidedByUserProvider} from '../../services/Item/Item.service';
import {UltyModalContext} from '../Shared/UltyModal/UltyModalContext';
import useUser from '../Shared/UserProvider/useUser';
import {isProviderAPos, setProviderItemInventory} from '../../services/Provider/Provider.service';
import {getPropertyValue, wrapValueInObject} from '../../helpers/object';
import {UltyInputText} from '@ulty/ulty-ui';
import {Link, useHistory, useParams} from 'react-router-dom';
import useBreadcrumb from '../NavBar/useBreadcrumb';
import ItemAvailabilitySetter from '../Items/ItemAvailabilitySetter';
import InventoryEdit from '../Items/Inventory/InventoryEdit';


const ExtraEdit = () => {
    const {id} = useParams();
    const [t] = useTranslation();
    const history = useHistory();
    const {user, can} = useUser();
    const {setPaths} = useBreadcrumb();
    const [isLoading, setIsLoading] = useState(false);
    const [canEditItem, setCanEditItem] = useState(false);
    const [canDeleteItem, setCanDeleteItem] = useState(false);

    const [form, setForm] = useState({
        id: 0,
        providerIds: [],
        inventory: {
            quantity: 0,
            unavailableUntil: null,
            externalId: ''
        },
        image: null,
        containsAlcohol: false,
        isUncountable: false,
        externalId: '',
        object: {
            name: '',
            description: '',
        }
    });
    const [formErrors, setFormErrors] = useState({});
    const { handleUltyModal } = useContext(UltyModalContext) ||  {};

    useEffect(() => {
        if (id) {
            loadExtraItem(id);
        } else {
            setCanEditItem(true);
        }
    }, [id]);

    useEffect(() => {
        setPaths([{
            text: t('breadcrumb.home'),
            link: true,
            path: '/'
        }, {
            text: t('breadcrumb.catalog'),
            link: true,
            path: '/products'
        }, {
            text: form?.object?.name || t('global.loading'),
            link: true,
            path: `/extras/edit/${id}`
        }, {
            text: t('breadcrumb.edition'),
            link: false,
        }]);
    }, [form, id, t]);

    const loadExtraItem = async (itemId) => {
        setIsLoading(true);

        try {
            const extraItem = await getExtra(itemId);
            const providerIds = extraItem.providers.map(p => p.id);
            setForm(prev => ({
                ...prev,
                id: extraItem.id,
                providerIds,
                inventory: extraItem.inventory,
                image: extraItem.images.find(image => image.isDefault),
                containsAlcohol: extraItem.containsAlcohol,
                isUncountable: extraItem.isUncountable,
                externalId: extraItem.externalId,
                object: {
                    name: extraItem.object.name,
                    description: extraItem.object.description,
                }
            }));

            const userOwnItem = isItemProvidedByUserProvider(extraItem, user);
            setCanEditItem(userOwnItem && can('UPDATE', 'extras'));
            setCanDeleteItem(userOwnItem && can('UPDATE', 'extras'));
        } catch (e) {
            notify.show(`${t(`global.${e.message}`)}`, 'error');
        } finally {
            setIsLoading(false);
        }
    }

    const checkErrors = () => {
        const mandatoryFields = ['object.name'];

        let hasErrors = false;
        let errorObject = {};
        mandatoryFields.forEach(mandatoryField => {
            const fieldValue = getPropertyValue(form, mandatoryField);
            if (!fieldValue || `${fieldValue}`.trim() === '') {
                errorObject = {
                    ...wrapValueInObject(formErrors, mandatoryField, true),
                    ...errorObject,
                }

                hasErrors = true;
            }
        });
        setFormErrors(errorObject);
        return hasErrors;
    }

    const handleChangeItem = (_evt, { name, value, checked, type }) => {
        setForm(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));

        setFormErrors(prev => ({
            ...prev,
            [name]: false,
        }));
    }

    const handleChangeObject = ({name, value, type, checked, }) => {
        setForm(prev => ({
            ...prev,
            object: {
                ...prev.object,
                [name]: type === 'checkbox' ? checked : value
            }
        }));

        setFormErrors(prev => ({
                ...prev,
                object: {
                    ...prev.object,
                    [name]: false,
                }
            }
        ));
    }

    const handleChangeInventory = ({ name, value, type, checked }) => {
        setForm(prev => {
            return {
                ...prev,
                inventory: {
                    ...prev.inventory,
                    [name]: type === 'checkbox' ? checked : value
                }
            }
        });
    }

    const handleChangeUncountable = ({ name, value, checked, type }) => {
        handleChangeItem(null,{ name, value, checked, type })

        setForm(prev => ({
            ...prev,
            inventory: {
                ...prev.inventory,
                quantity: checked ? null : 0
            }
        }))
    }

    const handleDeleteItem = async () => {
        const modalSettings = {
            title: `${t('extra_edit.deleteConfirm.title')} ${form.object.name} ?`,
            content: <>
                <p>{t('extra_edit.deleteConfirm.message')}</p>
            </>,
            onValidate: async () => {
                await archive(form.id, { archived: true });
                handleUltyModal(false);
                navigateToProductsList();
            }
        };
        handleUltyModal(true, modalSettings);
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        if (checkErrors()) {
            notify.show(t('global.missingFields'), 'error');
            setIsLoading(false);
            return;
        }

        try {
            let savedExtra = form;
            if (can('UPDATE', 'extra')){
                savedExtra = await saveExtra(form);
                setForm(() => ({
                    ...savedExtra,
                    image: savedExtra.images.find(image => image.isDefault),
                    inventory: form.inventory,
                    providerIds: savedExtra.providers.map(p => p.id)
                }));
            }

            let inventory = null;
            if (isProviderAPos(user.provider)) {
                inventory = await setProviderItemInventory(user.provider.id, savedExtra.id, {
                    quantity: form.inventory.quantity,
                    externalId: form.inventory.externalId,
                    unavailableUntil: form.inventory.unavailableUntil,
                });

                setForm(prev => ({
                    ...prev,
                    inventory
                }));
            }

            notify.show(t('global.registerSuccess'), 'success');
            history.push(`/extras/edit/${savedExtra.id}`);
        } catch (e) {
            console.log(e);
            notify.show(t('global.anErrorOccurred'), 'error');
        } finally {
            setIsLoading(false);
        }
    }

    const navigateToProductsList = () => {
        history.push('/products');
    }

    return (
        <Form>
            <Segment>
                <Grid>
                    <Grid.Row columns={2} stretched>
                        <Grid.Column width={8}>
                            <Form.Field>
                                <label>{t('extra_edit.image')}</label>
                                <Uploader
                                    disabled={!canEditItem}
                                    initial={form.image?.url || ""}
                                    withConversion={true}
                                    onSrcChange={(url) => {
                                        const image = url ? {url, isDefault: true} : null;
                                        handleChangeItem({}, { name: 'image', value: image })
                                    }}
                                />
                            </Form.Field>

                            {!!form.id && canEditItem &&
                                <Grid.Row>
                                    <Grid.Column textAlign='center' css={css`text-align: center;`}>
                                        <Link to={`/items/${form.id}/platforms`}><Icon name='settings' />{t('item_edit.manageImages')}</Link>
                                    </Grid.Column>
                                </Grid.Row>}
                        </Grid.Column>

                        <Grid.Column tablet={8} mobile={16} computer={8}>
                            <UltyInputText
                                name="name"
                                readonly={!canEditItem}
                                value={form.object.name}
                                onChange={e => handleChangeObject(e.target)}
                                error={formErrors?.object?.name}
                                label={t('extra_edit.name')}
                                placeholder={t('extra_edit.name')}
                            />

                            <UltyInputText
                                name="externalId"
                                readonly={!canEditItem}
                                value={form.externalId}
                                onChange={(e) => handleChangeItem(e, { name: e.target.name, value: e.target.value })}
                                label={t('item_edit.externalId')}
                                placeholder={t('item_edit.externalId')}
                            />

                            {isProviderAPos(user.provider) &&
                                <>
                                    <InventoryEdit
                                        type={'EXTRA'}
                                        inventory={form.inventory}
                                        isUncountable={form.isUncountable}
                                        isLoading={isLoading}
                                        onChange={handleChangeInventory}
                                    />

                                    <span>
                                    {id &&
                                        <ItemAvailabilitySetter
                                            css={css` margin-top: 1rem; `}
                                            itemId={id}
                                            onChange={(unavailableUntil) => handleChangeInventory({name : "unavailableUntil", value: unavailableUntil})}
                                        />
                                    }
                                </span>
                                </>
                            }

                            <div css={css`
                              display: flex;
                              justify-content: space-between;
                            `}>
                                <Form.Field>
                                    <label>&nbsp;</label>
                                    <Checkbox label={t('item_edit.contains_alcohol')}
                                              checked={form.containsAlcohol}
                                              onChange={(e, {type, checked, name}) => handleChangeItem(e,{type, checked, name})}
                                              name="containsAlcohol"
                                              disabled={!canEditItem}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>&nbsp;</label>
                                    <Checkbox label={t('item_edit.is_uncountable')}
                                              checked={form.isUncountable}
                                              onChange={(_e, {name, value, type, checked}) => {
                                                  handleChangeUncountable({name, value, checked, type})
                                              }}
                                              name="isUncountable"
                                              disabled={!canEditItem}
                                    />
                                </Form.Field>
                            </div>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Column width={16}>
                        <Form.Field
                            control={TextArea}
                            name="description"
                            value={form.object.description}
                            error={formErrors.object?.description}
                            onChange={(e, {type, name, value}) => handleChangeObject({type, name, value})}
                            label={t('extra_edit.description')}
                            placeholder={t('extra_edit.description')}
                            disabled={!canEditItem}
                        />
                    </Grid.Column>

                    <Grid.Column width={16}>
                        <div style={{ textAlign: 'right', marginTop: '10px' }}>
                            { id && canDeleteItem && (
                                <Button
                                    type="button"
                                    color="red"
                                    onClick={handleDeleteItem}
                                    loading={isLoading}
                                >
                                    <Icon name={'delete'} />
                                    {t('global.delete')}
                                </Button>
                            )}
                            <Button
                                type="submit"
                                color="teal"
                                loading={isLoading}
                                disabled={isLoading}
                                onClick={handleSubmit}>{t('global.save')}</Button>
                        </div>
                    </Grid.Column>
                </Grid>
            </Segment>
        </Form>
    )
}

export default ExtraEdit;
