import React, {useEffect, useState} from 'react';
import {getRoles} from '../../services/Role/role.service';
import {Dropdown} from 'semantic-ui-react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';


const RoleSelector = ({selectedRole, onRoleChange}) => {
    const [t] = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        loadRoles();
    }, []);

    const loadRoles = async () => {
        setIsLoading(true);
        setRoles(await getRoles());
        setIsLoading(false);
    }

    const getRolesOptions = () => {
        return roles.map(role => ({
            text: t(`role.${role.name}`),
            value: role.name,
            key: role.id
        }));
    }

    const handleSelectedRoleChange = (value) => {
        onRoleChange(roles.find(role => role.name === value));
    }

    return (
        <Dropdown
            clearable
            fluid
            search
            selection
            loading={isLoading}
            disabled={isLoading}
            value={selectedRole?.name || null}
            onChange={(e, { value }) => handleSelectedRoleChange(value)}
            options={getRolesOptions()}
        />
    )
}

RoleSelector.propTypes = {
    selectedRole: PropTypes.object,
    onRoleChange: PropTypes.func
}

export default RoleSelector;
