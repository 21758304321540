/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {CardGroup} from 'semantic-ui-react';
import UltyLoadingPlaceholderCard from './UltyLoadingPlaceholderCard';
import PropTypes from 'prop-types';


const UltyLoadingPlaceholderCardGroup = ({itemsPerRow = 4, cardQuantity = 8}) => {
    return (
        <CardGroup itemsPerRow={itemsPerRow} css={css` flex: 1; `}>
            {[...Array(cardQuantity).keys()].map((i) => (
                <UltyLoadingPlaceholderCard key={i} />
            ))}
        </CardGroup>
    );
};

UltyLoadingPlaceholderCardGroup.propTypes = {
    itemsPerRow: PropTypes.number,
    cardQuantity: PropTypes.number,
};

export default UltyLoadingPlaceholderCardGroup;
