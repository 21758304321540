/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {Button, Dropdown} from 'semantic-ui-react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {getMerchantItems} from '../../../services/Item/Item.service';
import {debounce} from 'lodash';
import { getCancellationSource } from '../../../services/http-client';
import { useScanning } from '../../../scanning/scanning.service';


const ItemSelector = ({
    placeholder,
    filters,
    excludedItemIds,
    onItemAdd,
    onItemChange,
    preloadedSearch=undefined,
    selectFirstAtLoad=false,
    clearable=true,
    searchFunction=null,
    itemRenderer=null
}) => {
    const [t] = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState(preloadedSearch ?? '');
    const retrieveItems = useCallback(searchFunction ?? getMerchantItems);
    const [hasMoreResult,sethasMoreResult] = useState(false);
    const [searchQuery,setSearchQuery] = useState(preloadedSearch);

    useScanning((barcodeEvent) => {
        setSearchQuery(barcodeEvent.value);
        setSearch(barcodeEvent.value);
    })

    placeholder =  placeholder || t('item_selector.add_element');
    //HACK. Ensure reload when filter object reference is not changed, but inner object is.
    const jsonFilters = JSON.stringify(filters);

    const dropdownRef = useRef(null);

    const loadItems = useCallback(
        async (cancellationSource) => {
            setIsLoading(true);
            const size = 15;
            const merchantItems = await retrieveItems({
                search,
                unavailableUntil: null,
                ...filters,
                page,
                size
            },cancellationSource);

            if(cancellationSource?.isCancelled) return;

            setItems(prev => [...prev, ...merchantItems.items].filter((value, index, array) => array.findIndex(value2 => value2.id === value.id) === index))
           if(merchantItems.items.length < size){
            sethasMoreResult(false);
           }
           else {
            sethasMoreResult(true)
           }

            setIsLoading(false)
        },
        [selectFirstAtLoad,jsonFilters,page, search],
    )

    useEffect(() => {
        const source = getCancellationSource()
        const loader = async () => {
           await loadItems(source)
        };

        loader();

        return () => source.cancel();
    }, [loadItems]);

    useEffect(() => {
        if(!items || items.length === 0) return;
        if(!selectFirstAtLoad || selectedItem)  return;

        const [firstItem] = items;
        handleOnSelectedItemChange(firstItem.id)
    })


    const scrollHandler = useCallback((event) => {
        if(isLoading || !hasMoreResult) return;

        const list = event.target
        const threshold = 0.7
        const currentPos = (Math.round(list.scrollHeight) - Math.round(list.scrollTop));
        const thresholdReached = currentPos <= (list.clientHeight* (1+threshold));

        if (thresholdReached) {
            if (!isLoading) {
                setIsLoading(true);
                setPage(prev => prev + 1)
            }
        }
    }, [isLoading,hasMoreResult])

    useEffect(() => {
        const dropDown = dropdownRef.current.ref.current.childNodes[3]
        dropDown.addEventListener('scroll', scrollHandler)

        return () => {
            dropDown.removeEventListener('scroll', scrollHandler)
        }

    }, [scrollHandler]);



    const getItemsOptions = useCallback((items) => {
        let nonExcludedItems = items;
        if (excludedItemIds) {
            nonExcludedItems = items.filter(i => !excludedItemIds.includes(i.id));
        }
        return nonExcludedItems.map(i => ({
            ...(itemRenderer && itemRenderer(i)),
            text: i.object.name,
            value: i.id,
            key: i.id,
            barcode: i.object.barcode,
        }));
    },[excludedItemIds, itemRenderer]);

    const availableOptions = useMemo(() => {
        return [
            {
                text:placeholder,
                disabled:true,
            },
            ...getItemsOptions(items)
        ];
    },[getItemsOptions,items]
    )



    const handleAddItem = () => {
        if (!selectedItem) {
            return;
        }

        onItemAdd(selectedItem);
        setSelectedItem(null);
    }

    const handleOnSelectedItemChange = (selectedItemId) => {
        if (!selectedItemId) {
            setSelectedItem(null);
        }

        const itemToSelect = items.find(i => i.id === selectedItemId);
        const optionToSelect = availableOptions.find(i => i.key === selectedItemId);
        if (!itemToSelect) {
            setSelectedItem(null)
        } else {
            setSelectedItem(itemToSelect)
        }


        onItemChange?.(itemToSelect,optionToSelect)
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            if(selectedItem) {
                e.preventDefault();
            }
            handleAddItem()
        }
    }

    const debounceSearch = useMemo(() => (
        debounce((searchQuery) => {
            setItems([])
            setPage(1)
            setSearch(searchQuery)
        }, 500)
    ), []);

    const handleSearchChange = (_e, { searchQuery }) => {
        debounceSearch(searchQuery)
    }

    return (<div css={css`
      display: flex;

      div {
        margin-right: 0.78em !important;
      }

      button {
        margin-left: 0.78em !important;
      }
    `}>
        <Dropdown
            placeholder={placeholder}
            clearable={clearable}
            fluid
            searchQuery={searchQuery}
            search={(curr, searchQuery) => {
                return curr.filter(c => {
                    return !search || c.text.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 || (c.barcode && c.barcode.indexOf(searchQuery) !== -1);
                })
            }}
            onSearchChange={handleSearchChange}
            selection
            loading={isLoading}
            value={selectedItem ? selectedItem.id : 0}
            onChange={(_e, { value }) => handleOnSelectedItemChange(value)}
            onKeyPress={handleKeyPress}
            noResultsMessage={isLoading ?  t('item_selector.search_in_progress') :  t('item_selector.no_item_found')}
            options={availableOptions}
            scrolling
            ref={dropdownRef}
        />

        {onItemAdd &&
            <Button
                icon='plus'
                color='teal'
                disabled={!selectedItem}
                onClick={handleAddItem}
            />
        }
    </div>)
};

ItemSelector.propTypes = {
    placeholder: PropTypes.string,
    filters: function(props, propName,componentName,location,fullPropName) {
            // isRequired if searchFunction is not provided
            if(props['searchFunction'] && typeof(props['searchFunction']) === 'function' && props[propName] === undefined){
                return PropTypes.object.isRequired(props,propName,componentName,location,fullPropName)
            }
    },
    excludedItemIds: PropTypes.arrayOf(PropTypes.string),
    onItemAdd: PropTypes.func,
    onItemChange: PropTypes.func,
    selectFirstAtLoad: PropTypes.bool,
    clearable: PropTypes.bool,
    searchFunction: PropTypes.func,
    itemRenderer: PropTypes.func,
    preloadedSearch: PropTypes.string
}

export default ItemSelector;
