import {call, METHODS} from '../http-client';

const ROUTE_PREFIX = 'devices';

export const registerDevice = async (token, manual = false) => {
    return await call(METHODS.PUT, ROUTE_PREFIX, `register`, {
        token,
        manual,
    });
}
