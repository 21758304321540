/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import React, { useReducer, useEffect } from 'react'
import { Table, Icon } from 'semantic-ui-react'
import _ from 'lodash';
import { Link, withRouter } from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import 'moment/locale/fr';
import {UltyMoney} from '@ulty/ulty-ui';
import useUser from '../Shared/UserProvider/useUser';

const filtersToQueryString = (filters) => {
  const strings = {
    merchant: filters.merchantId,
    brands: filters.brandIds,
    platforms: filters.platformIds,
    from: filters.dateRange.startDate.toISOString(),
    to: filters.dateRange.endDate.toISOString(),
  };

  const queryStrings = Object.keys(strings).map(key => {
    let value = strings[key];

    if (!value) {
      return '';
    }

    if (Array.isArray(value)) {
      if(value.length > 0) {
        value=value.join();
      } else if (value.length === 0) {
        return ''
      }
    }

    return `&${key}=${value}`
  });

  const queryString = queryStrings.join('');
  return queryString.replace('&', '?');
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'STATE_UPDATE':
      let newdata = _.sortBy(action.data, [state.column])
      if (state.direction === 'descending') {
        newdata = newdata.reverse()
      }

      return {
        ...state,
        data: newdata
      }

    case 'CHANGE_SORT':
      if (state.column === action.column) {
        return {
          ...state,
          data: _.sortBy(state.data, [action.column]).reverse(),
          direction:
            state.direction === 'ascending' ? 'descending' : 'ascending',
        }
      }

      return {
        column: action.column,
        data: _.sortBy(state.data, [action.column]),
        direction: 'ascending',
      }
    default:
      throw new Error()
  }
}

const DataTable = ({ dataDaily, filters }) => {
  const [t] = useTranslation();
  const {user} = useUser();

  const [state, dispatch] = useReducer(reducer, {
    column: null,
    data: dataDaily,
    direction: null,
  })
  const { column, data, direction } = state

  useEffect(() => {
    dispatch({ type: 'STATE_UPDATE', data: dataDaily })
  }, [dataDaily])

  return (
    <React.Fragment>
      <Table color="teal" sortable fixed>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={column === 'dt' ? direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'dt' })}
            >
              {t('home.day')}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right"
              sorted={column === 'revenues' ? direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'revenues' })}
            >
              {t('home.revenues')}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right"
              sorted={column === 'nborder' ? direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'nborder' })}
            >
              {t('home.number_order')}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right"
              sorted={column === 'avgorder' ? direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'avgorder' })}
            >
              {t('home.average_order')}
            </Table.HeaderCell>
            <Table.HeaderCell css={css` width: 60px; `}/>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          { (data||[]).map(dd => {
            return (
              <Table.Row key={dd.dt}>
                <Table.Cell>
                  {dd.dt}&nbsp;&nbsp;
                  { dd.max && (<Icon name="trophy" color="yellow" />)}
                </Table.Cell>
                <Table.Cell textAlign="right">
                  <UltyMoney
                      readonly
                      currencyCode={user.getCompanyCurrency()}
                      locale={user.getCompanyLocale()}
                      amount={dd.revenues}
                  />
                </Table.Cell>
                <Table.Cell textAlign="right">{`${parseInt(dd.nborder)}`}</Table.Cell>
                <Table.Cell textAlign="right">
                  <UltyMoney
                      readonly
                      currencyCode={user.getCompanyCurrency()}
                      locale={user.getCompanyLocale()}
                      amount={Math.round(dd.nborder > 0 ? dd.revenues/dd.nborder: 0)}
                  />
                </Table.Cell>
                <Table.Cell textAlign="right">
                  <Link as="a" to={`/orders/finished${filtersToQueryString({
                    ...filters,
                    dateRange: {
                      startDate: moment.utc(dd.dt).startOf('day'),
                      endDate: moment.utc(dd.dt).endOf('day')
                    },
                  })}`} size="mini" icon>
                    <Icon name="eye" color="teal" />
                  </Link>
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </React.Fragment>
  )
}

export default withRouter(DataTable);
