/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Label, Button, Grid, Segment, Icon, Form, Input, List } from 'semantic-ui-react'
import { UltyInputText } from '@ulty/ulty-ui'
import { useTranslation } from 'react-i18next'
import { useMemo, useState } from 'react'
import { deleteMarkupSetup } from '../../../services/Category/Category.service'
import { notify } from 'react-notify-toast'
import useUser from '../../Shared/UserProvider/useUser';

import MarkupPriceChecker from './MarkupPriceChecker'
import { groupBy,sortAsc } from '../../../helpers/array'
import { memo } from 'react'
import { DayWeekDisplay } from '../../Shared/WeekDays'
import { MarkupList } from './MarkupList'
import { CategoryName, isMonoSlotSet } from '../Shared/CategoryName'

const MarkupSetupItem = ({ markupSetup, categorySetLinkId, categorySetId, onDelete, displaySim = false }) => {
    const [t] = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const { user } = useUser();

    const handleDeleteMarkupSetup = async () => {
        try {
            setIsLoading(true)
            await deleteMarkupSetup(markupSetup.categorySetLinkId, markupSetup.id)
            onDelete()
        } catch (e) {
            notify.show(t('global.anErrorOccurred'), 'error');
        } finally {
            setIsLoading(false)
        }
    }

    const isFull = useMemo(() => {
        return (isMonoSlotSet(markupSetup.set, markupSetup.slot) || !markupSetup.slot)
                &&  !markupSetup.category
    },[markupSetup])

    return (
        
        <Segment color={markupSetup.highlight && 'teal'} >
      
            {markupSetup.highlight && <Label corner size='tiny' color='teal'><Icon name='star'></Icon></Label>}
            <Grid columns='equal' stackable verticalAlign='middle' >
                <Grid.Row >
                    <Grid.Column>        
                        
                    <Label  horizontal  size='large'>
                    {markupSetup.markup} %
                    </Label>
                        {isFull
                            ?<strong>{t("markup.add.all_slots")}</strong>
                            : <CategoryName
                                categorySet={markupSetup.set}
                                categorySlot={markupSetup.slot}
                                category={markupSetup.category}
                            /> }       
                    </Grid.Column>

                    <Grid.Column width={8}>
                        <div css={css`
                                  display: flex;
                                  flex-direction: column;
                                `}>
                            <UltyInputText
                                readonly={true}
                                label={t('days.days_of_week')}
                            />
                        <DayWeekDisplay daysOfWeek={markupSetup.daysOfWeek} hours={markupSetup.hours ||[]}/>
                        </div>
                        
                    </Grid.Column>

                    <Grid.Column css={css`align-self: center`}>
                        {markupSetup.editable && (<Button
                            color="red"
                            compact
                            basic
                            floated='right'
                            negative
                            size="tiny"
                            loading={isLoading}
                            onClick={handleDeleteMarkupSetup}
                        >{t('global.delete')}</Button>)}
                        {!markupSetup.editable && markupSetup.providerId !== user.provider.id && (
                            <Label 
                                color="blue"
                                compact
                                basic
                                floated='right'
                                negative
                                size="tiny"

                            >{t('global.company')}</Label>
                        )}
                    </Grid.Column>
                </Grid.Row>
                {displaySim && (<Grid.Row>
                    <Grid.Column stretched>
                    <div css={css`
                                  display: flex;
                                  font-size:0.8em;
                                  flex-direction: column;
                                `}>
                        <MarkupPriceChecker
                            markupSetupContext={({
                                categorySetLinkId,
                                categorySetId,
                                categorySlotId: markupSetup?.slot?.id,
                                categoryId: markupSetup?.category?.id
                            })}
                            currentMarkupSetupId={markupSetup.id}
                        />
                    </div>
                    </Grid.Column>

                </Grid.Row>)}
            </Grid>

        </Segment>
    )
}

export default MarkupSetupItem
