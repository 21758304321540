import React, { useEffect, useState } from 'react';
import {getOrderDenyReasonTypes} from '../../../services/Order/Order.service';
import {useTranslation} from 'react-i18next';
import {Form, Dropdown, TextArea, Button} from 'semantic-ui-react';
import PropTypes from 'prop-types';


const OrderDenyReasonSelector = ({itemsFromOrder, isSubmitting, onDenyReasonSubmit}) => {
    const initialForm = {
        type: null,
        details: null
    }

    const [t] = useTranslation();
    const [isLoading, setIsLoading]  = useState(false);
    const [form, setForm] = useState(initialForm);
    const [denyReasonTypes, setDenyReasonTypes] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        getOrderDenyReasonTypes()
            .then(denyReasonTypes => {
                setDenyReasonTypes(denyReasonTypes);
                setIsLoading(false);
            })
            .catch(e => {
                console.log(e);
                setIsLoading(false);
            });
    }, []);

    const handleDenyReasonTypeChange = (e, { value }) => {
        setForm(prev => ({
            ...prev,
            type: denyReasonTypes.find(denyReasonType => denyReasonType.id === value)
        }));
    }

    const handleItemsOutOfStockChange = (e, {value}) => {
        setForm(prev => ({
            ...prev,
            details: {
                ...prev.details,
                outOfStockItems: value
            }
        }));
    }

    const handleDenyReasonDetailNotesChange = (e, {value}) => {
        setForm(prev => ({
            ...prev,
            details: {
                ...prev.details,
                notes: value
            }
        }));
    }

    const handleSubmitRefuseOrderWithReason = () => {
        onDenyReasonSubmit(form);
    }

    return (<Form>
        <Form.Field>
            <Dropdown
                selection
                clearable
                placeholder={t('order.denyReason.choose_an_order_deny_reason')}
                disabled={isLoading}
                loading={isLoading}
                value={form.type?.id}
                name="type"
                onChange={handleDenyReasonTypeChange}
                options={denyReasonTypes?.map(denyReasonType => ({
                    text: t(`order.denyReason.types.${denyReasonType.name}`),
                    value: denyReasonType.id,
                    key: denyReasonType.id,
                }))}
            />
        </Form.Field>

        {form.type &&
            <>
                {form.type.name === 'ITEMS_OUT_OF_STOCK' &&
                    <Form.Field>
                        <Dropdown
                            selection
                            clearable
                            multiple
                            disabled={isLoading}
                            loading={isLoading}
                            value={form.details?.outOfStockItems || []}
                            name="itemsOutOfStock"
                            onChange={handleItemsOutOfStockChange}
                            options={itemsFromOrder.map(item => ({
                                text: item.object.name,
                                value: item.id,
                                key: item.id,
                            }))}
                        />
                    </Form.Field>
                }

                <Form.Field
                    control={TextArea}
                    name="notes"
                    onChange={handleDenyReasonDetailNotesChange}
                />
            </>
        }

        <Button
            type='submit'
            disabled={!form.type}
            loading={isSubmitting}
            onClick={handleSubmitRefuseOrderWithReason}
        >{t('global.validate')}</Button>
    </Form>)
}

OrderDenyReasonSelector.propTypes = {
    itemsFromOrder: PropTypes.array,
    isSubmitting: PropTypes.bool,
    onDenyReasonSubmit: PropTypes.func.isRequired
};

export default OrderDenyReasonSelector;
