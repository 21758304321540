import {rawCall, call, METHODS} from '../http-client';
import {filtersToQueryString} from '../Filter/filter.service';

const ROUTE_PREFIX = 'order';

export const getOrder = async (id) => {
    return call(METHODS.GET, ROUTE_PREFIX, id);
}

export const getOrderDeliveryInfo = async (id) => {
    return await call(METHODS.GET, ROUTE_PREFIX, `${id}/delivery`);
}

export const getOrders = async (state, body) => {
    return await call(METHODS.GET, ROUTE_PREFIX, state, { params: body });
}

export const getOrderEvents = async (orderId) => {
    return call(METHODS.GET, ROUTE_PREFIX, `${orderId}/orderEvents`)
}

export const downloadMetrics = async (body) => {
    return await rawCall(METHODS.POST, ROUTE_PREFIX, `download`, body, {
        responseType: 'blob',
        timeout: 90000,
    });
}

export const downloadVatOrders = async (body) => {
    return await rawCall(METHODS.POST, ROUTE_PREFIX, `download/vat`, body, {
        responseType: 'blob',
        timeout: 150000,
    });
}

export const getOrderDenyReasonTypes = async () => {
    return await call(METHODS.GET, ROUTE_PREFIX, 'denyReasonTypes');
}

export const denyOrder = async (order, denyReason) => {
    return await call(METHODS.POST, ROUTE_PREFIX, `${order.id}/deny`, { order, denyReason });
}

export const convertOrderItemsToFulfillmentIssues = (orderItemsWithFulfillmentIssues, orderItemsReplacements) => {
    return orderItemsWithFulfillmentIssues.map(orderItemsWithFulfillmentIssue => {
        const substitution = orderItemsReplacements.find(op => op.fulfillmentIssueParentId === orderItemsWithFulfillmentIssue.id);

        if (substitution) {
            return {
                issueType: 'SUBSTITUTION',
                itemId: orderItemsWithFulfillmentIssue.itemId,
                substitution: {
                    id: substitution.substitutionId,
                    itemId: substitution.itemId,
                    itemQuantity: substitution.quantity,
                    ...(substitution.scanned ? {scanned: true} : {})
                }
            };
        } else if (orderItemsWithFulfillmentIssue.quantity) {
            return {
                issueType: 'PARTIAL_AVAILABILITY',
                itemId: orderItemsWithFulfillmentIssue.itemId,
                itemAvailable: +orderItemsWithFulfillmentIssue.quantity,
            };
        } else {
            return {
                issueType: 'OUT_OF_ITEM',
                itemId: orderItemsWithFulfillmentIssue.itemId,
                issueAction: 'REMOVE_ITEM',
            };
        }
    });
}

export const setFulfillmentIssues = async (order, fulfillmentIssues) => {
    return await call(METHODS.PUT, ROUTE_PREFIX, `${order.id}/fulfillmentIssues`, { fulfillmentIssues });
}


export const setPickingMethod = async (order, pickingMethod,orderItemIds) => {
    return await call(METHODS.PUT, ROUTE_PREFIX, `${order.id}/pickingMethod`, {
        pickingMethod,
        orderItemIds
    });
}

export const getFulfillmentAlternatives = async ({order, orderItem, needle, quantity,page,size},cancellationSource=null) => {
    const queryParams = filtersToQueryString({needle, quantity,page,size});
    return await call(
        METHODS.GET,
        ROUTE_PREFIX,
        `${order.id}/fulfillmentAlternative/${orderItem.itemId}${queryParams}`,
        cancellationSource?.patchAxiosOptions({})
        );
}

export const acceptOrder = async (order, delayPickupTimeMinutes) => {
    return await call(METHODS.POST, ROUTE_PREFIX, `${order.id}/accept`, { order, delayPickupTimeMinutes });
}

export const cancelOrder = async (order) => {
    return await call(METHODS.DELETE, ROUTE_PREFIX, `${order.id}`);
}

export const updateOrderCouriers = async (order, couriers) => {
    return await call(METHODS.POST, ROUTE_PREFIX, `${order.id}/couriers`, { couriers });
}
