
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { UltyInputText, UltyMoney } from '@ulty/ulty-ui';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Dimmer, Form, Loader } from 'semantic-ui-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import useUser from '../../Shared/UserProvider/useUser';
import usePlatform from '../../Shared/PlatformProvider/usePlatforms';
import { getSorter, groupBy } from '../../../helpers/array';
import { debounce } from 'lodash';
import { usePricesByPlatform } from '../../Prices/PricesByPlatform/usePricesByPlatform';

const PlatformPricesEdit = ({ basePrice, pricesByPlatform, isLoading, onChange }) => {
    const [t] = useTranslation();
    const { user } = useUser();
    const { get: getPlatform, list } = usePlatform();
    const [customPrice, setCustomPrice] = useState(!!(pricesByPlatform?.length > 0));

    const [changeNotification, setChangeNotification] = useState(false);

    const [prices, setPrices] = useState(pricesByPlatform || []);
    const {displayedPrices} = usePricesByPlatform(prices);


    const notifyChange = useCallback((changeType,itemChange = null) => {
        if (changeType === 'REMOVE') {
            setPrices(d => d.filter(p => p !== itemChange));
            setChangeNotification('DELETE');
        }
        else if (changeType === "REMOVE_ALL") {
            setPrices([]);
            setChangeNotification('DELETE');
        }
        else if( changeType === "RESET"){
            setPrices(pricesByPlatform || []);
            setChangeNotification('RESET');
        }
        else if(changeType === 'INSERT'){
            setPrices(d => [...d, { ...itemChange }])
            setChangeNotification('INSERT');
        }
        else if(changeType == 'UPSERT'){
            if(itemChange.providerId !== user.provider.id){
                notifyChange('INSERT',{...itemChange, providerId: user.provider.id})
            }
            else {
                setPrices(d => {
                    const toUpdate = d.find(({providerId,platformId}) => platformId === itemChange.platformId && providerId === itemChange.providerId);
                    toUpdate.price = itemChange.price;
                    return d;
                })
                
                setChangeNotification('UPDATE')
            }
        }
    }, [setChangeNotification, setPrices]);

    useEffect(() => {
        setPrices(pricesByPlatform || []);
    }, [pricesByPlatform])

    useEffect(() => {
        if (changeNotification) {
            onChange(
                {
                    name: "pricesByPlatform",
                    value: prices,
                    type: changeNotification
                }
            )
            setChangeNotification(false);
        }
    }, [prices, changeNotification])

    useEffect(() => {
        setCustomPrice(pricesByPlatform?.length > 0);
    }, [pricesByPlatform?.length]);


    const remainingPlatforms = useMemo(() => {
        const currentSetPlatforms = new Set(prices.map(p => p.platformId));
        return list().filter(platform => !currentSetPlatforms.has(platform.id))
    }, [list, prices])

    return (
        <>
            <Dimmer.Dimmable>
                <>
                    <Form.Field>
                        <Checkbox
                            toggle
                            checked={!!customPrice}
                            label={t('item_edit.item_price.by_platform_toggle')}
                            onChange={(evt, { checked }) => {
                                setCustomPrice(checked);
                                notifyChange(checked ? 'RESET' : 'REMOVE_ALL')
                            }}
                        />
                    </Form.Field>

                    {customPrice && displayedPrices.length > 0 && displayedPrices.map((p, i) => (
                        <UltyMoney
                            key={`price_${p.platformId}`}
                            name={`pricesByPlatform_${i}`}
                            label={`${t('item_edit.inventory.sellPrice')} ${getPlatform(p.platformId || '')?.name}`}
                            onMoneyAmountChange={(e, amount) => {
                                notifyChange('UPSERT',{...p,price:amount})
                            }}

                            {...(!p.providerId || p.providerId === user.provider.id) && {
                                action:{
                                    color: 'grey',
                                    icon: 'close',
                                    size: 'tiny',
                                    onClick: notifyChange.bind(this,'REMOVE',p)
                                },
                                actionPosition:'left'
                            }}

                           
                            amount={p.price}
                            currencyCode={user.getCompanyCurrency()}
                            locale={user.getCompanyLocale()}
                        />

                    ))}

                    {customPrice && remainingPlatforms.length > 0 && (
                            <>
                            {remainingPlatforms.map(p => (
                                <Button
                                css={css`
                                    margin-top:0.3em !important;
                                `}
                                basic
                                size='small'
                                onClick={notifyChange.bind(this,'INSERT',{ platformId: p.id, price: basePrice,providerId: user.provider.id })}
                                >
                                {t('item_edit.item_price.add_platform', { platformName: p.name })}
                            </Button>
                            ))}
                            </>
                    )}
                </>
            </Dimmer.Dimmable>
        </>
    )
}

PlatformPricesEdit.propTypes = {
    basePrice: PropTypes.number,
    pricesByPlatform: PropTypes.arrayOf(PropTypes.shape(
        {
            platformId: PropTypes.string.isRequired,
            price: PropTypes.number.isRequired,
        })).isRequired,
    isLoading: PropTypes.bool,
    onChange: PropTypes.func
}

export default PlatformPricesEdit;
