import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Dropdown} from 'semantic-ui-react';
import {getMerchantsOfCompany} from '../../services/Company/Company.service';

const MerchantSelector = ({ companyId, selectedMerchant, onMerchantChange }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [merchants, setMerchants] = useState([]);

    useEffect(() => {
        loadMerchantsOfCompany(companyId);
    }, [companyId]);

    const loadMerchantsOfCompany = async (companyId) => {
        setIsLoading(true);
        setMerchants(await getMerchantsOfCompany(companyId));
        setIsLoading(false);
    }

    const getMerchantsOptions = () => {
        return merchants.map(merchant => ({
            text: merchant.name,
            value: merchant.id,
            key: merchant.id
        }));
    }

    const handleMerchantChange = (value) => {
        onMerchantChange(merchants.find(merchant => merchant.id === value));
    }

    return (
        <Dropdown
            clearable
            fluid
            search
            selection
            loading={isLoading}
            disabled={isLoading}
            value={selectedMerchant?.id || null}
            onChange={(e, { value }) => handleMerchantChange(value)}
            options={getMerchantsOptions()}
        />
    )
}

MerchantSelector.propTypes = {
    companyId: PropTypes.string.isRequired,
    selectedMerchant: PropTypes.object,
    onMerchantChange: PropTypes.func
}

export default MerchantSelector;
