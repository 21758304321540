import { useEffect, useMemo } from "react";
import {
    Icon,
    Message,
} from 'semantic-ui-react';

import useFeature from "../components/Shared/FeatureProvider/useFeature";
import { useTranslation } from "react-i18next";

export const useScanning = (callback = undefined) => {

    const {isEnabled} = useFeature();

    const isDeviceCompatible = useMemo(()=>{
        const scanningCapabilities = window?.Android?.getScanningCapabilities?.();
        return scanningCapabilities && scanningCapabilities !== "none";
    },[]);

    const isAvailable = useMemo(()=>{
        return isDeviceCompatible && isEnabled('scan_picking')
    },[isDeviceCompatible,isEnabled]);

    useEffect(() => {
        if(isAvailable){
            window.addEventListener('barcode', callback, false);
            return () => {
                window.removeEventListener('barcode', callback);
            };
        }
    },[callback,isAvailable]);

    return {isAvailable}
}


export const ScanningInformationLabel = ({}) => {

    const {isAvailable} = useScanning();
    const [t] = useTranslation();

    return <>
        {isAvailable &&  <Message
            icon="info"
            size="mini"
            info
            attached="bottom"
            content={t("order.pickup.scan_available_label")}>
        </Message>}
    </>

}